import safeRegex from 'safe-regex2';

import {CreditCard} from '../credit-cards/credit-card.class';
import {Exceptions} from '../exceptions/exceptions.class';
import {OrderHeader} from './order-header.class';
import {PriceInfo} from '../items/item.class';
import {StandardJSONResponse} from '../tools/AS.types';
import {User} from '../users/user.class';

export const COMPACTVIEWTHRESHOLD = 49;
export const validOrderRegexString = `[P|R]([A-Z0-9]{5}|[A-Z0-9]{1})[0-9]{4}`;
export type ApprovalOrderAction = `approve` | `decline` | `delete`;
export type CheckoutSource = `checka.asp` | `checks.asp`;
export type DownloadOrderItemType = `full` | `simple`;
export type MobileType = `a` | `w`;
export type OrderLineSortOrder = `amount_0` | `amount_9` | `entNbr_9` | `item_a` | `item_z` | `name_a` | `name_z`;
export type OrderViewType = `compact` | `standard`;
export type SavedForLaterAction = 'add' | 'remove';
type OrderLineAlertType =
    | `bulk-price`
    | `dropship-frt`
    | `dropship`
    | `ds-canada`
    | `first-time-buy`
    | `item-split`
    | `logistics`
    | `low-stock`
    | `no-pri-split`
    | `on-demand`
    | `out-of-stock`
    | `program-fee`
    | `special-price`
    | `third-party`
    | `ups-hazardous`;
type PCodeType = 'freeItem' | 'freeShipping' | 'giftCard' | 'itemDiscount' | 'ordrDiscount';
export type ApprovalOrderSortOrder =
    | `amount_0`
    | `amount_z`
    | `lastUpdate_a`
    | `lastUpdate_z`
    | `ordrLogin_a`
    | `ordrLogin_z`
    | `ordrNbr_a`
    | `ordrNbr_z`;

export interface ApprovalLevels {
    level1?: Approver[] | null;
    level2?: Approver[] | null;
    level3?: Approver[] | null;
}
export interface Approver {
    approver: string;
    apprvStatus: string;
    date?: null;
    approved: boolean;
    required: boolean;
    name: string;
    level: number;
}

export interface ApprovalAccount {
    account: string;
    city: string;
    name: string;
    orders: ApprovalOrder[];
    siteid: string;
}

export interface ApprovalListRes {
    accounts: ApprovalAccount[];
    approvalOrderCount: number;
    result: string;
}

export interface ApprovalOrder {
    name: string;
    account: string;
    actionNeeded: boolean;
    amount: number;
    approvals?: ApprovalLevels[];
    approved: boolean;
    comment?: string;
    imagePath: string[];
    images: string[];
    lastUpdate: string;
    ordrDt: string;
    ordrLines: number;
    ordrLogin: string;
    ordrNbr: string;
    po: string;
    remarks: Remarks[];
    status: `In Process` | `Order Declined` | `Pending Approval`;
}

export interface ApproveOrdersParams {
    account: string;
    emailCopy: boolean;
    login: string;
    orders: OrderToApprove[];
    security: string;
}

export interface Backorder {
    account: string;
    accountCode: string;
    badge: string;
    billTo: ImpAddress;
    costCenter: string;
    defaultShipVia: string;
    isFedex: boolean;
    lines?: BackorderLine[];
    location: string;
    loginName: string;
    nonstock: boolean;
    orderReadOnly: boolean;
    ordProc: string;
    ordrDt: string;
    ordrLines: number;
    ordrNbr: string;
    origInvoice: string;
    payterm: string;
    po: string;
    project: string;
    rep: Rep;
    result: string;
    shipTo: ImpAddress;
    shipVia: string;
    shipViaDesc?: string;
    status: string;
    validShipVias: string;
}

export interface BackorderLine {
    amount: number;
    assortment: boolean;
    auto: boolean;
    boxes: number;
    etaDt: string;
    excludeSt?: string[];
    imagePath: string;
    item: string;
    name: string;
    pkgQty: number;
    prcbk: string;
    prettyid: string;
    priceC: number;
    pricePK: number;
    qty: number;
}

export interface CheckoutForm {
    'acct-code'?: string;
    'ce-ref': string;
    'cost-center'?: string;
    'ship-via': `A` | `D` | `E` | `F` | `W` | `X` | `Y` | `Z` | `1` | `2` | `3` | `5` | `9`;
    _csrf: string;
    allowancePcode?: string;
    allowancePcodeApplied?: `true` | `false`;
    apprvcmt?: string;
    badge?: string;
    div848?: boolean;
    emailcopy: `on` | `off`;
    emailshipping: `on` | `off`;
    location: string;
    nonrev?: string;
    payvia: string;
    pcode?: string;
    po: string;
    project?: string;
    taxable?: `true` | `false`;
}

export interface CheckRestrictedItemsRes {
    deleted: boolean;
    deletedItems: DeletedRestrictedItem[];
    result: 'OK';
}

export interface CreateOrderParams {
    account: string;
    isPunchout: boolean;
    loginName: string;
    mobileType: MobileType;
    punchOutId: string;
    securityString: string;
}

export interface CreateOrderRes {
    account: string;
    billTo: ImpAddress;
    cid: number;
    location: string;
    ordrDt: string;
    ordrNbr: string;
    po: string;
    punchTo?: ImpAddress;
    result: string;
    shipTo: ImpAddress;
    shipvia: string;
    validShipVias?: string;
}

export interface DcDetails {
    name: string;
    number: number;
    priority: number;
    recStatus?: string;
    shipDt: string;
    shipNbr: string;
}

export interface DeleteOrderRes {
    ok?: boolean;
    error?: string;
}

export interface DeletedRestrictedItem {
    image: string;
    imagePath: string;
    item: string;
    name: string;
    pkgQty: number;
    qtyOrd: number;
}

export interface GetTrackingInfoRes {
    addr1: string;
    addr2: string;
    attn: string;
    city: string;
    date: string;
    dcs?: Dcs[] | null;
    externalErrorMessage?: string;
    invoice: string;
    msg: string;
    name: string;
    order: string;
    ordType: string;
    result: string;
    siteId: string;
    st: string;
    status: string;
    zip: string;
}

export interface Dcs {
    dc: number;
    dcName: string;
    images?: string[] | null;
    itemCnt: number;
    packageCnt: number;
    packages?: Packages[] | null;
}

export interface Packages {
    trackNbr: string;
    shipDate: string;
    shipDesc: string;
    shipVia: string;
    vendorName: string;
    vendorURL: string;
    trackURL: string;
    itemCnt: number;
    images?: string[];
    shipmentDetails: ShipmentDetails;
}

export interface ImpAddress {
    ['ctry-code']?: string;
    addr1: string;
    addr2: string;
    attn: string;
    city: string;
    name: string;
    residential?: boolean;
    st: string;
    zip: string;
}

export interface ImpRep {
    email: string;
    login: string;
    name: string;
    phone: string;
    rep: number;
}

export interface ImpSubtotal {
    amount: number;
    id: number;
    name: string;
}

export interface ApproveOrderRes {
    msgs: string[];
    order: string;
    result: `Error` | `OK`;
}

export interface OrderAsXML {
    ACCOUNT_NUMBER: string;
    EMAIL: string;
    NAME: string;
    ORDER_FREIGHT: number;
    ORDER_SUBTOTAL: number;
    ORDER_TAX: number;
    ORDER_TOTAL_COST: number;
    PRICED_ITEMS_NTAB: OrderLineAsXML[];
}

interface OrderCharge {
    amount: number;
    code: number;
    estDiscAmt?: number;
    name: string;
    percent?: number;
}

export interface OrderLineAlert {
    days?: number;
    itemQtyLimit?: number;
    pandemicItem?: boolean;
    pandemicItemBlocked?: boolean;
    special?: number;
    type: OrderLineAlertType;
}

export interface OrderLine {
    alerts?: OrderLineAlert[];
    amount: number;
    bin?: string[];
    campid?: number;
    canDiscount?: boolean;
    canShipAir: boolean;
    checked: boolean;
    custPunchItem?: string;
    dc: DcDetails;
    entNbr: number;
    exceptions: Exceptions;
    excludeSt?: string[];
    extraDesc?: string;
    fullWebcat: string;
    graMsdsavail_b: boolean;
    GSDP_ID_s: string;
    image: string;
    imagePath: string;
    item: string;
    itemNeedsAppr?: boolean;
    lotTrack: boolean;
    manItem?: string;
    manName?: string;
    manual: boolean;
    messages?: string[];
    mroStatus?: boolean;
    mroStatusMessage?: string;
    name: string;
    onBackorder?: string;
    orderLineError?: string;
    ormd: boolean;
    pkgQty: number;
    prcbk: string;
    prettyid: string;
    priceC: number;
    pricePK?: number;
    pricePlatform?: number;
    promo: boolean;
    prop65b_b: boolean;
    prop65b_chemicals_s?: string;
    prop65c_b: boolean;
    prop65Message?: string;
    qtyLimit: number;
    qtyOrd: number;
    qtyShp: number;
    quantityPlatform?: number;
    sdsLink?: string;
    subtotId?: number;
    surcharge?: boolean;
    type: string;
    unitsOrdered: number;
    uomPlatform?: string;
    upsHazard: boolean;
}

export interface OrderLineAsXML {
    MR_PRICED_ITEMS_TYP: {
        EXTENDED_LINE_ITEM_PRICE: number;
        ITEM_DESCRIPTION: string;
        ITEM_NUMBER: string;
        PACKAGE_QUANTITY: number;
        UNITS_ORDERED: number;
    };
}

export interface Remark {
    action: string;
    comment: string;
    seqNbr: number;
    time: string;
    user: string;
}

export interface Remarks {
    date: string;
    remark: Remark[];
}

export interface ShareOrderItemsListIdRes {
    listId: number;
    result: `OK`;
}

export interface ShipmentDetails {
    delayReason?: string;
    deliveryDate?: string;
    errorCode?: string;
    errorMessage?: string;
    estimatedArrival?: string;
    receivedBy?: string;
    signedBy?: string;
    status: 'Shipping' | 'Out of Delivery' | 'Delivered' | 'Delayed' | 'Unknown';
    statusDetails?: EventDay[];
}

export interface EventDay {
    events: ShipmentEvent[];
    date: string;
}

export interface ShipmentEvent {
    eventLocation?: string;
    eventDescription: string;
    date: string;
}

export interface OrderToApprove {
    action: ApprovalOrderAction;
    comment: string;
    order: string;
    po?: string;
}

export interface Rep {
    login?: string;
    loginName?: string;
    name: string;
    rep: number;
}

export interface SharedOrderItems {
    ezid: number;
    itemCnt: number;
    items?: ItemsEntity[] | null;
    result: string;
}

export interface SplitOrderParams {
    account?: string;
    login?: string;
    order: string;
    splitItems: string[];
}

export interface SubmitQuoteParams {
    account?: string;
    comment: string;
    coName?: string;
    fullName?: string;
    login?: string;
    order: string;
    phone: string;
    securityString?: string;
}

export interface SplitOrderRes extends StandardJSONResponse {
    newOrder: string;
}

export interface ItemsEntity {
    binLocation: string;
    brand: string;
    description: string;
    fullWebcat: string;
    imagePath: string;
    item: string;
    pkgQty: number;
    prettyItem: string;
    priceInfo: PriceInfo;
    quantity: number;
    unitsOrdered: number;
    wid: number;
}

export interface UpdateOrderParams {
    ['ship-via']?: string;
    account?: string;
    addr1?: string;
    addr2?: string;
    attn?: string;
    btaddr1?: string;
    btaddr2?: string;
    btattn?: string;
    btcity?: string;
    btname?: string;
    btst?: string;
    btzip?: string;
    city?: string;
    ctrycode?: string;
    forcetax?: boolean;
    location?: string;
    loginname?: string;
    mobile?: MobileType;
    name?: string;
    order?: string;
    po?: string;
    saveaddr?: 'no' | 'yes';
    shipmethods?: string;
    st?: string;
    zip?: string;
}

export interface VocType {
    reqImage: boolean;
    reqWebCond: boolean;
    reshipReq: boolean;
    commentReq: boolean;
    type: string;
    typeDesc: string;
}

export interface VocCondition {
    condition: string;
    conditionDesc: string;
}

export interface ReturnItem {
    condition: string;
    dc: string;
    files: number;
    item: string;
    itemComment?: string;
    reship: string;
    returnQty: number;
    selectItem: boolean;
    type: string;
}

export interface UpdateSavedForLaterItemsRes {
    result: string;
}

export class Order {
    ['login-name']: string;
    account: string;
    accountCode: string;
    allLines: OrderLine[];
    allowanceApplied?: boolean;
    allowanceDesc?: string;
    allowancePcode?: string;
    amount: number;
    amountSetup: boolean;
    approvalAmt: number;
    aprxFrtChrg?: number;
    aprxFrtTot: number;
    aprxTax: number;
    ariba: boolean;
    aribaDt?: string;
    badge: string;
    billTo: ImpAddress;
    billToLock: boolean;
    caDropshipMsg?: boolean;
    cannotUseCreditCard: boolean;
    canSplitPmt?: boolean;
    cc: CreditCard[];
    charges: OrderCharge[];
    cid: number;
    costCenter: string;
    currency?: string;
    currRate: number;
    custPoRequired: boolean;
    custPoRequiredAmt: number;
    dcOrder: string;
    dcs: null;
    defaultShipVia: string;
    emailOrder: boolean;
    errMsg: string;
    error?: string;
    errorsArray: string[];
    estimatedWeight: string;
    estSplitBalance?: number;
    faxOrder: boolean;
    forceTax: boolean;
    freightAccount: string;
    frtMsg?: string;
    frtPaidAmt?: number;
    frtPending: boolean;
    giftDesc?: string;
    giftEmail?: string;
    hasAllowance?: boolean;
    hasBackorder: boolean;
    hasMinOrdAmt?: boolean;
    hazardousFeeAmount: number;
    isFedex: boolean;
    location: string;
    managerComment: string;
    managerStatus: boolean;
    messages: string[];
    minOrdAmt?: number;
    mobileOrder: string;
    mustProvidePO: boolean;
    mustUseCreditCard?: boolean;
    orderCC?: CreditCard; // CC used for order
    orderLines: number;
    orderNbr: string;
    orderReadOnly: boolean;
    orderTotal: number;
    ordProc: string;
    ordrDt: string;
    ordrLines: number;
    ordrNbr: string;
    ordrNeedsApprv: boolean;
    payterm: string;
    pcode: string;
    pcodeOptions?: 'single' | 'multi';
    pcodeType?: PCodeType;
    po: string;
    poHelp?: string;
    poRegex?: string;
    project: string;
    punchIn: boolean;
    quoteComment?: string;
    quoteExpDt: string;
    recStatus: number;
    remainingBal?: number;
    rep: ImpRep;
    rma: boolean;
    scanErrors: string[];
    scanner: boolean;
    shipMeter: boolean;
    shipTo: ImpAddress;
    shipToLock: boolean;
    shipVia: string;
    shipViaDesc: string;
    shopSetup: boolean;
    someNonManual: boolean;
    status: `Expired`;
    statusDt: string;
    subtotals?: ImpSubtotal[];
    surcharge?: boolean;
    taxCode: `E` | `S` | `T`;
    taxPercent: number;
    type: `` | `Q`;
    userApprvNeeded: boolean;
    validShipVias: string;
    warningsArray: string[];
    webOrder: boolean;

    constructor(orderData) {
        // Assign props from orderData
        Object.assign(this, orderData);

        // Transform data
        this._changeAprxFrtTotToNum();
    }

    /**
     * Returns true if an order can be deleted
     * @param order
     * @param user
     */
    public static canDelete(order: Order, user: User): boolean {
        // Orders in managerStatus cannot be deleted
        if (order.managerStatus) {
            return false;
        }

        // Quotes can only be deleted by the creator
        return !(order.type === `Q` && order[`login-name`] !== user.loginName);
    }

    /**
     * Determines if user can proceed from order detail page
     */
    public canProceed(isPunchout: boolean, isPunchin: boolean): boolean {
        // Order must have items on it
        if (this.orderLines === 0) {
            return false;
        }

        // Punchout or punchin users cannot proceed with errors
        if ((isPunchout || isPunchin) && this.errorsArray && this.errorsArray.length) {
            return false;
        }

        // Default is true
        return true;
    }

    /**
     * Used to determine the orderDetailView type based on preference
     * Default view is 'standard'. Cookie takes priority.
     * @param orderLines - Number of lines on the order
     * @param orderDetailViewCookie - User's orderDetailView cookie value
     */
    public static determineOrderViewType(orderLines: number, orderDetailViewCookie?: string): OrderViewType {
        if (orderDetailViewCookie && orderDetailViewCookie === `compact`) {
            return `compact`;
        } else if (orderDetailViewCookie && orderDetailViewCookie === `standard`) {
            return `standard`;
        } else if (orderLines > COMPACTVIEWTHRESHOLD) {
            return `compact`;
        }
        return `standard`;
    }

    /**
     * Returns label to use for the checkout button
     */
    public getCheckoutButtonLabel(): string {
        if (this.ordrNeedsApprv) {
            return `Send For Approval`;
        } else {
            return `Place Order`;
        }
    }

    /**
     * Returns the appropriate URL to provide corresponding messaging
     */
    public getDeleteUrl(): string {
        return `/orders?${this.type === 'Q' ? 'deletedQuote' : 'deletedOrder'}=${this.ordrNbr}`;
    }

    /**
     * Returns the OrderLine matching provided itemID
     * @param itemID
     */
    public getOrderLineByItemId(itemID: string): OrderLine {
        if (itemID && this.allLines && this.allLines.length) {
            for (const orderLine of this.allLines) {
                if (orderLine.item && orderLine.item.toLowerCase() === itemID.toLowerCase()) {
                    return orderLine;
                }
            }
        }

        // orderLine not found
        return null;
    }

    /**
     * Provides example text for poHelp
     */
    public getPoExample() {
        if (this.poHelp && this.poHelp === `Must have exactly 8 characters`) {
            return `xxxxxxxx or xxxx-xxxx`;
        } else {
            return ``;
        }
    }

    /**
     * Translates a number string to a carrier for improved readability in your
     * order templates
     * @returns - Human readable shipVia
     */
    public getPrettyShipVia(): string {
        switch ((this.shipVia || ``).toLowerCase()) {
            case `1`:
                return `UPS Ground`;
            case `2`:
                return `Parcel Post`;
            case `3`:
                return `Common Carrier (Truck)`;
            case `4`:
                return `Pickup/Delivery`;
            case `5`:
                return `UPS Second Day`;
            case `9`:
                return `FedEx Ground (RPS)`;
            case `a`:
                return `UPS Next Day Air`;
            case `d`:
                return `UPS Third Day`;
            case `e`:
                return `Expeditors (Canada)`;
            case `f`:
                return `FedEx International`;
            case `i`:
                return `International`;
            case `w`:
                return `FedEx Standard Overnight`;
            case `x`:
                return `FedEx Priority Overnight`;
            case `y`:
                return `FedEx 2 Day`;
            case `z`:
                return `FedEx Express Saver(3 day)`;
            default:
                return `Unknown (${this.shipVia})`;
        }
    }

    /**
     * Returns true if the user has corporate allowance available for payment
     */
    public get hasCorpAllowance(): boolean {
        return !!this.hasAllowance && this.remainingBal > 0 && !!this.allowancePcode;
    }

    /**
     * Loops through allLines and each of their alerts and returns true if at least one item is marked as pandemicItem
     */
    public get hasPandemicItems(): boolean {
        let foundPandemic = false;
        this.allLines.forEach((item) => {
            if (foundPandemic) {
                return;
            }
            if (!item.alerts) {
                return;
            }
            item.alerts.forEach((alert) => {
                if (alert.pandemicItem) {
                    foundPandemic = true;
                }
            });
        });
        return foundPandemic;
    }

    /**
     * Tests if the order provided can be edited
     * @param order - Order to be tested
     * @returns - If the order can be edited
     */
    public static isEditable(order: Order | OrderHeader): boolean {
        if (!order) {
            return false;
        }
        return !order.orderReadOnly;
    }

    /**
     * Returns true if provided addressString is a PO Box
     * @param addressString - Address string to test
     */
    public static isPoBox(addressString: string): boolean {
        if (addressString) {
            const isPoBoxRegex = /^(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i;
            return isPoBoxRegex.test(addressString);
        }

        // Falsy values are not a PO Box
        return false;
    }

    /**
     * Tests validity of order number
     * @param ordrNbr - Order number to test
     */
    public static isValidOrderNumber(ordrNbr: string): boolean {
        const validOrderRegex = new RegExp(`^${validOrderRegexString}$`, `i`);
        return validOrderRegex.test(ordrNbr);
    }

    /**
     * Determines if supplied US or Canadian zip code is valid
     * @param zipCode - Zip code to evaluate
     */
    public static isValidUSAndCanadianZipCode(zipCode: string): boolean {
        const canadaAndUsZipCodes = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
        return canadaAndUsZipCodes.test(zipCode);
    }

    /**
     * Determines if supplied US zip code is valid
     * @param zipCode - Zip code to evaluate
     */
    public static isValidUsZipCode(zipCode: string): boolean {
        const usZipCodes = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return usZipCodes.test(zipCode);
    }

    /**
     * Returns true if order lines should be grouped by require approval
     */
    public groupByRequireApproval(): boolean {
        return !!this.cid && this.ordrNeedsApprv;
    }

    /**
     * Returns true if order approval amount meter should be displayed
     */
    public showOrderApprovalMeter(): boolean {
        return !!(this.amountSetup && this.approvalAmt);
    }

    /**
     * Returns true if shipping meter should be displayed
     */
    public showShippingMeter(user: User): boolean {
        return !!(user && user.canViewPrice() && this.shipMeter && this.frtPaidAmt);
    }

    /**
     * Determines if shippingSaverAdSavings should be shown
     * @param user
     */
    public showShippingSaverAdSavings(user: User) {
        const orderAmountAsNum = this.amount;
        return (
            orderAmountAsNum > 100 &&
            orderAmountAsNum < 399 &&
            user.allowShippingSaver() &&
            !user.hasActiveShippingSaver() &&
            this.aprxFrtChrg !== 0
        );
    }

    /**
     * Sorts order lines by B3G1 promotion priority
     * @param orderLines - OrderLine array to sort
     */
    public static sortOrderLines(orderLines: OrderLine[]): OrderLine[] {
        const sortedOrderLines = orderLines;

        // Sort orderLines
        sortedOrderLines.sort((orderLine1, orderLine2) => {
            // Show promo items first
            if (orderLine1.promo < orderLine2.promo) {
                return 1;
            }
            if (orderLine1.promo > orderLine2.promo) {
                return -1;
            }

            // Show items associated with promo next
            if (orderLine1.campid < orderLine2.campid) {
                return 1;
            }
            if (orderLine1.campid > orderLine2.campid) {
                return -1;
            }
            return 0;
        });

        // Return sortedOrderLines
        return sortedOrderLines;
    }

    /**
     * Tests if the supplied poNumber is valid for that order
     * @param poNumber - PO Number to evaluate
     * @returns Validation error message (or empty string if valid)
     */
    public validatePoNumber(poNumber: string): string {
        // If no poNumber and order requires it, return error
        if (!poNumber && this.mustProvidePO) {
            return `Must provide a PO number`;
        }

        // If the order has a specified regex, test that
        if (this.poRegex) {
            if (!safeRegex(this.poRegex)) {
                return `Invalid PO RegEx setup`;
            }
            const validPoRegex = new RegExp(`${this.poRegex}`, `i`);
            if (!validPoRegex.test(poNumber || ``)) {
                return `Invalid PO number - ${this.poHelp}`;
            }
        }

        // Else return no message
        return ``;
    }

    /**
     * Changes aprxFrtTot to num, to be consistent with other representations
     * @private
     */
    private _changeAprxFrtTotToNum() {
        if (this.aprxFrtTot && typeof this.aprxFrtTot === `string`) {
            this.aprxFrtTot = parseFloat(this.aprxFrtTot as any);
        }
    }
}
