import * as React from 'react';

type EmptyStateProps = {
    className?: string;
    message: string | React.JSX.Element;
};

export const EmptyState = ({className, message}: EmptyStateProps) => {
    return (
        <div
            className={`tw-flex tw-items-center tw-justify-center tw-w-full tw-text-gray-400 tw-h-[275px]${
                className ? ` ${className}` : ''
            }`}
        >
            {message}
        </div>
    );
};
