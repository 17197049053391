import * as React from 'react';
import {useEffect} from 'react';

import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import Button from '../../ui/Buttons/Button';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {showChatWindow} from '../../contacts/contact.functions';

export interface NullResultsProps {
    searchString: string;
}

export const NullResults = ({searchString}: NullResultsProps) => {
    // Fire analytics on display
    useEffect(() => {
        pushGaEvent(`ga4_search_overlay_null_result`, {had_search_results: false, search_input: searchString});
    }, [searchString]);

    /**
     * Template
     */
    return (
        <>
            <h1 className="tw-border-b-0">
                0 results found for "<span className="tw-text-red-500">{searchString}</span>"
            </h1>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-12 tw-gap-5 tw-mb-6">
                <div className="tw-col-span-1 lg:tw-col-span-7 tw-w-full tw-relative">
                    <div className="tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-5 tw-h-full">
                        <div className="tw-col-span-1 tw-mb-4 md:tw-mb-0 tw-relative tw-w-full">
                            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border tw-rounded-sm tw-h-full">
                                <div className="tw-p-4 tw-h-full tw-flex tw-items-center">
                                    <div>
                                        <div className="tw-font-bold">Use Exact Phrase</div>
                                        <div className="tw-text-sm tw-font-normal tw-whitespace-normal">
                                            Enter an item/model number or brand
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-col-span-1 tw-mb-4 md:tw-mb-0 tw-relative tw-w-full">
                            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border tw-rounded-sm tw-h-full">
                                <div className="tw-p-4 tw-h-full tw-flex tw-items-center">
                                    <div>
                                        <div className="tw-font-bold">Check Spelling</div>
                                        <div className="tw-text-sm tw-font-normal tw-whitespace-normal">
                                            Even a small typo can make a big difference
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-hidden lg:tw-block tw-col-span-1 tw-relative tw-w-full">
                            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border tw-rounded-sm tw-h-full">
                                <div className="tw-p-4 tw-h-full tw-flex tw-items-center">
                                    <div>
                                        <div className="tw-font-bold">Try Different Words</div>
                                        <div className="tw-text-sm tw-font-normal tw-whitespace-normal">
                                            You will get different results when searching 'glove' vs 'nitrile gloves'
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-col-span-1 lg:tw-col-span-5 tw-w-full tw-relative">
                    <div className="tw-flex tw-w-full">
                        <div className="tw-w-full tw-ml-0 xl:tw-w-2/3 xl:tw-ml-[33.3333%]">
                            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-gray-100 tw-rounded-sm tw-border-0">
                                <div className="tw-p-4 tw-flex tw-flex-col">
                                    <div className="tw-font-bold">We can help you find it.</div>
                                    <div className="tw-mb-4 tw-text-sm tw-font-normal tw-leading-[1.3]">
                                        We are available M-F, 6 AM to 6 PM CST <br />
                                        Call{' '}
                                        <a
                                            href="tel:18005582808"
                                            onClick={() => {
                                                gaLegacyCustomEvent({
                                                    eventAction: `null_search_phone_number_click`,
                                                    eventCategory: `Search Overlay`,
                                                    eventLabel: searchString.trim(),
                                                });
                                                pushGaEvent(`ga4_search_overlay_null_action`, {
                                                    click_type: `click_to_call`,
                                                    search_input: searchString,
                                                });
                                            }}
                                        >
                                            1-800-558-2808
                                        </a>
                                    </div>
                                    <Button
                                        block={true}
                                        className="tw-w-full"
                                        onClick={() => {
                                            showChatWindow();
                                            gaLegacyCustomEvent({
                                                eventAction: `null_search_start_chat`,
                                                eventCategory: `Search Overlay`,
                                                eventLabel: searchString.trim(),
                                            });
                                            pushGaEvent(`ga4_search_overlay_null_action`, {
                                                click_type: `start_chat`,
                                                search_input: searchString,
                                            });
                                        }}
                                        size="sm"
                                        variant="secondary"
                                    >
                                        Start Chat
                                    </Button>
                                    <Button
                                        block={true}
                                        className="tw-w-full tw-mt-2"
                                        onClick={() => {
                                            gaLegacyCustomEvent({
                                                eventAction: `null_search_send_email`,
                                                eventCategory: `Search Overlay`,
                                                eventLabel: searchString.trim(),
                                            });
                                            pushGaEvent(`ga4_search_overlay_null_action`, {
                                                click_type: `send_email`,
                                                search_input: searchString,
                                            });
                                            location.assign('/contact-us');
                                        }}
                                        size="sm"
                                        variant="secondary"
                                    >
                                        Send Email
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
