import {Dispatch, SetStateAction, useState} from 'react';

import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ItemToAdd} from '../../order-items/order-items.class';
import {Item} from '../../items/item.class';
import {OrderItemsService} from '../../../client/order-items/order-items.service';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {OrderLine} from '../../orders/order.class';
import {ProgressOverlay} from '../../../client/components/ProgressOverlay';
import {useService} from '../../react/ServiceContext';

interface UseOrdersProps {
    componentName: string;
    gaItemListName: GaItemListName;
}

export const useOrderLines = ({componentName, gaItemListName}: UseOrdersProps) => {
    const [orderLineErrors, setOrderLineErrors] = useState<ImpError[]>([]);
    const orderItemsService: OrderItemsService = useService(`orderItemsService`);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);

    /**
     * TBD
     * @param itemsToAdd - Array of items to add
     * @param message - Message to display in the toast
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param useAddToOrderModal - Used to control which UI method is used
     * @param afterAddToOrderAction - Optional action to perform after addToOrder is complete
     */
    const addToOrder = (
        itemsToAdd: ItemToAdd[],
        message?: string,
        increment?: boolean,
        useAddToOrderModal?: boolean,
        afterAddToOrderAction?: () => void,
    ) => {
        setOrderLineErrors(null);

        // Record analytics
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: gaItemListName});

        // Use add to order modal if specified
        if (useAddToOrderModal) {
            orderItemsWorkflow.addToOrderModal(itemsToAdd, componentName, !!increment);
            return;
        }

        // Else use toast
        orderItemsWorkflow.addToOrderToast(itemsToAdd, componentName, !!increment, message).subscribe({
            next: () => {
                if (afterAddToOrderAction) {
                    afterAddToOrderAction();
                }
            },
            error: (addToOrderToastErr: ImpError) => {
                setOrderLineErrors([addToOrderToastErr]);
            },
        });
    };

    /**
     * Deletes a single order line from the current order
     * @param orderLine - Line to delete from the order
     */
    const deleteOrderLine = (orderLine: OrderLine) => {
        ProgressOverlay.activate();
        setOrderLineErrors(null);
        orderItemsService
            .deleteFromOrder(orderLine)
            .then(() => {
                ProgressOverlay.deactivate();
            })
            .catch((deleteFromOrderErr: ImpError) => {
                ProgressOverlay.deactivate();
                setOrderLineErrors([deleteFromOrderErr]);
            });
    };

    /**
     * Deletes multiple items from the current order
     * @param itemsToRemove - Items to remove
     */
    const deleteOrderLines = (itemsToRemove: ItemToAdd[]) => {
        ProgressOverlay.activate();
        setOrderLineErrors(null);
        orderItemsService
            .deleteOrderLines(componentName, itemsToRemove)
            .then(() => {
                ProgressOverlay.deactivate();
            })
            .catch((deleteOrderLinesErr: ImpError) => {
                ProgressOverlay.deactivate();
                setOrderLineErrors([deleteOrderLinesErr]);
            });
    };

    /**
     * Updates the order line quantity, 0 removes
     * @param orderLine - OrderLine to update
     * @param newQty - New quantity to set
     * @param setShowDeleteOrderLineConfirmModal - For confirmation modal
     */
    const updateItemQty = (orderLine: OrderLine, newQty: number, setShowDeleteOrderLineConfirmModal: Dispatch<SetStateAction<boolean>>) => {
        if (newQty === 0) {
            setShowDeleteOrderLineConfirmModal(true);
        } else {
            addToOrder(
                [
                    {
                        item: Item.uglyItem(orderLine.item),
                        list: gaItemListName,
                        unitsOrdered: newQty,
                    },
                ],
                `Order Updated`,
            );
        }
    };

    // Return properties
    return {
        addToOrder,
        deleteOrderLine,
        deleteOrderLines,
        orderLineErrors,
        updateItemQty,
    };
};
