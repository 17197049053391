import * as React from 'react';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {
    faAngleRight,
    faBars,
    faBolt,
    faCabinetFiling,
    faCheckCircle,
    faClipboardList,
    faEnvelope,
    faExchangeAlt,
    faFileUpload,
    faHexagon,
    faHistory,
    faList,
    faPhoneSquare,
    faSyncAlt,
    faToolbox,
    faUserCircle,
    faMegaphone,
} from '@fortawesome/pro-solid-svg-icons';

import {AccountEntryModal} from '../../authentication/AccountEntryModal/AccountEntryModal';
import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {BodyScroll} from '../../ui/ScrollHandler';
import {FlyoutMenu} from '../../ui/Flyouts/FlyoutMenu';
import {gaLegacyCustomEvent, gaLegacyDataLayerPush} from '../../../client/ga/ga-legacy.functions';
import {getReactNativeVersion} from '../../user-agent/user-agent.helpers';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {ProductCategories} from '../mega-menu/ProductCategories/ProductCategories';
import {Radio} from '../../ui/forms/Radio/Radio';
import {UserAgent} from '../../../client/user-agent/user-agent.class';
import {UsersService} from '../../../client/users/users.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';

interface HeaderMobileMenuProps {
    user: User;
}

export const HeaderMobileMenu = ({user}: HeaderMobileMenuProps) => {
    const [flyoutTitle, setFlyoutTitle] = useState(``);
    const [isSubMenu, setIsSubMenu] = useState(false);
    const [showAccountEntryModal, setShowAccountEntryModal] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [showMyShopMenu, setShowMyShopMenu] = useState(false);
    const [showProductsMenu, setShowProductsMenu] = useState(false);
    const [topMenuOpen, setTopMenuOpen] = useState(false);
    const componentName = 'header-mobile-menu-component';
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const usersService: UsersService = useService(`usersService`);

    /**
     * TBD
     */
    const createNewOrder = () => {
        gaLegacyCustomEvent({eventAction: `create_new_order`, eventCategory: `Ecommerce`, eventLabel: `orders_menu`});
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                alert(createOrderErr.message);
            },
        });
    };

    /**
     * Selects the user's custom catalog
     * @param catalogId
     */
    const selectCatalog = (catalogId: string) => {
        usersService
            .selectCatalog(catalogId)
            .then(() => {
                location.reload();
            })
            .catch(() => {
                // Error silently
            });
    };

    /**
     * Template
     */
    return (
        <>
            <div
                className="tw-cursor-pointer tw-flex tw-items-center tw-p-3 tw-pr-4"
                aria-label="Hamburger Menu Icon"
                onClick={() => {
                    BodyScroll(false);
                    setFlyoutTitle(user.isLoggedIn() ? `Welcome` : `Log In`);
                    setIsSubMenu(false);
                    setTopMenuOpen(true);
                }}
            >
                <FontAwesomeIcon
                    className="tw-text-gray-600"
                    icon={faBars}
                    size="xl"
                />
            </div>
            <div className="tw-flex-grow tw-overflow-y-auto tw-overflow-x-hidden">
                <FlyoutMenu
                    isSubMenu={isSubMenu}
                    onClose={() => {
                        BodyScroll(true);
                        setTopMenuOpen(false);
                        setShowAccountMenu(false);
                        setShowMyShopMenu(false);
                        setShowProductsMenu(false);
                    }}
                    resetMenu={() => {
                        setFlyoutTitle(user.isLoggedIn() ? `Welcome` : `Log In`);
                        setIsSubMenu(false);
                        setShowAccountMenu(false);
                        setShowMyShopMenu(false);
                        setShowProductsMenu(false);
                        setTopMenuOpen(true);
                    }}
                    show={topMenuOpen || showAccountMenu || showProductsMenu || showMyShopMenu}
                    title={flyoutTitle}
                    width={350}
                >
                    {topMenuOpen && (
                        <div
                            className={`tw-overflow-x-hidden tw-max-w-[350px] tw-w-full tw-h-full tw-ml-0 tw-transform-[-350px] tw-duration-500 ${
                                topMenuOpen ? '' : ' tw-hidden'
                            }`}
                        >
                            {user.isLoggedIn() && (
                                <div className="tw-border-b tw-items-center tw-px-4 tw-py-2">
                                    <div className="maskPII">
                                        <h5>{user.fullName}</h5>
                                        <div>{user.city}</div>
                                        <div>{user.coName}</div>
                                        <div>{user.activeAccount ? `#${user.activeAccount} ` : ``}</div>
                                    </div>
                                </div>
                            )}
                            {user.hasCustomCatalogs() && user.hasMultipleCatalogs() && (
                                <div className="tw-border-b tw-px-4 tw-py-2">
                                    <h4>Catalogs</h4>
                                    {user.catalogList.catalogs.map((catalog) => (
                                        <Radio
                                            alias={`${catalog.cid}`}
                                            defaultChecked={catalog.cid.toString() === user.activeCatalogId}
                                            key={catalog.cid}
                                            label={catalog.name}
                                            name="header_catalogSelect"
                                            onChange={() => {
                                                selectCatalog(`${catalog.cid}`);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {!user.isLoggedIn() && (
                                <div className="tw-mt-4 tw-mx-4">
                                    <Button
                                        block={true}
                                        onClick={() => {
                                            usersService.showLoginPopUp(() => {
                                                setTopMenuOpen(false);
                                            });
                                        }}
                                        size="sm"
                                        type="button"
                                        variant="secondary"
                                    >
                                        Log In
                                    </Button>
                                    <Button
                                        block={true}
                                        className="tw-mt-2"
                                        onClick={() => location.assign('/create-account')}
                                        size="sm"
                                        type="button"
                                        variant="outline-secondary"
                                    >
                                        Create Account
                                    </Button>
                                </div>
                            )}
                            <div className="tw-px-2 tw-pt-2">
                                <ul className="tw-list-none tw-p-0 tw-m-0">
                                    {user.canSwitchAccount() && (
                                        <li className="!tw-leading-10 tw-mt-0">
                                            <Button
                                                className="!tw-text-gray-600 hover:!tw-underline"
                                                onClick={() => {
                                                    setTopMenuOpen(false);
                                                    BodyScroll(true);
                                                    setShowAccountFinderModal(true);
                                                }}
                                                size="sm"
                                                variant="link"
                                            >
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faExchangeAlt}
                                                        className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    />
                                                    <span>Switch Account</span>
                                                </>
                                            </Button>
                                        </li>
                                    )}
                                    {user.isAllAccountAccess() && (
                                        <li className="!tw-leading-10 tw-mt-0">
                                            <Button
                                                className="!tw-text-gray-600 hover:!tw-underline"
                                                onClick={() => {
                                                    setTopMenuOpen(false);
                                                    BodyScroll(true);
                                                    setShowAccountEntryModal(true);
                                                }}
                                                size="sm"
                                                variant="link"
                                            >
                                                <>
                                                    <FontAwesomeIcon
                                                        className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                        icon={faExchangeAlt}
                                                    />
                                                    <span>Enter Account</span>
                                                </>
                                            </Button>
                                        </li>
                                    )}
                                    {user.hasOrderApprovalAccess && (
                                        <li className="!tw-leading-10 tw-mt-0">
                                            <a
                                                className="tw-block tw-text-gray-600 tw-no-underline"
                                                href="/orders/approval"
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faCheckCircle}
                                                />
                                                <span>Order Approval</span>
                                            </a>
                                        </li>
                                    )}
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-block tw-text-gray-600 tw-no-underline"
                                            href="/orders"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faListAlt}
                                            />
                                            <span>Order Review</span>
                                        </a>
                                    </li>
                                    {user.hasJobCosting() && (
                                        <li className="tw-leading-10 tw-mt-0">
                                            <a
                                                className="tw-block tw-text-gray-600 tw-no-underline"
                                                href="/jobcosting"
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faClipboardList}
                                                />
                                                <span>Repair Orders</span>
                                            </a>
                                        </li>
                                    )}
                                    {user.hasRepairOrder() && (
                                        <li className="!tw-leading-10 tw-mt-0">
                                            <a
                                                className="tw-block tw-text-gray-600 tw-no-underline"
                                                href="/ro"
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faClipboardList}
                                                />
                                                <span>Repair Orders</span>
                                            </a>
                                        </li>
                                    )}
                                    <li className="!tw-leading-10 tw-mt-0">
                                        {user.isLoggedIn() ? (
                                            <>
                                                <Button
                                                    className="!tw-text-gray-600 tw-w-full hover:!tw-underline"
                                                    onClick={() => {
                                                        setFlyoutTitle(`My Account`);
                                                        setIsSubMenu(true);
                                                        setShowAccountMenu(true);
                                                        setTopMenuOpen(false);
                                                    }}
                                                    size="sm"
                                                    variant="link"
                                                >
                                                    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                                icon={faUserCircle}
                                                            />
                                                            <span>My Account</span>
                                                        </div>
                                                        <FontAwesomeIcon
                                                            className="tw-text-gray-600"
                                                            icon={faAngleRight}
                                                            size="lg"
                                                        />
                                                    </div>
                                                </Button>
                                            </>
                                        ) : (
                                            <a
                                                className="tw-text-gray-600"
                                                href="/account"
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faUserCircle}
                                                />
                                                <span>My Account</span>
                                            </a>
                                        )}
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <Button
                                            className="!tw-text-gray-600 tw-w-full hover:!tw-underline"
                                            onClick={() => {
                                                setFlyoutTitle(`Products`);
                                                setIsSubMenu(true);
                                                setShowProductsMenu(true);
                                                setTopMenuOpen(false);
                                            }}
                                            size="sm"
                                            variant="link"
                                        >
                                            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                <div>
                                                    <FontAwesomeIcon
                                                        className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                        icon={faHexagon}
                                                    />
                                                    <span>Products</span>
                                                </div>
                                                <FontAwesomeIcon
                                                    className="tw-text-gray-600"
                                                    icon={faAngleRight}
                                                    size="lg"
                                                />
                                            </div>
                                        </Button>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/order-history-items"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faHistory}
                                            />
                                            <span>Order History</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/lists"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faList}
                                            />
                                            <span>Lists</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <Button
                                            className="!tw-text-gray-600 hover:!tw-underline tw-w-full"
                                            onClick={() => {
                                                setFlyoutTitle(`My Shop`);
                                                setIsSubMenu(true);
                                                setShowMyShopMenu(true);
                                                setTopMenuOpen(false);
                                            }}
                                            size="sm"
                                            variant="link"
                                        >
                                            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                <div>
                                                    <FontAwesomeIcon
                                                        className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                        icon={faCabinetFiling}
                                                    />
                                                    <span>My Shop</span>
                                                </div>
                                                <FontAwesomeIcon
                                                    className="tw-text-gray-600"
                                                    icon={faAngleRight}
                                                    size="lg"
                                                />
                                            </div>
                                        </Button>
                                    </li>
                                    {!user.isPunchout && (
                                        <li className="!tw-leading-10 tw-mt-0">
                                            <a
                                                className="tw-text-gray-600"
                                                href="/manageautoreorders"
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faSyncAlt}
                                                />
                                                <span>Auto Reorder</span>
                                            </a>
                                        </li>
                                    )}
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/fileimport"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faFileUpload}
                                            />
                                            <span>File Import</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/quickadd"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faBolt}
                                            />
                                            <span>Quick-Add</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/services"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faToolbox}
                                            />
                                            <span>Services</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <Button
                                            className="!tw-text-gray-600 hover:!tw-underline"
                                            onClick={() => {
                                                gaLegacyDataLayerPush(`injectQualtricsScript`);
                                            }}
                                            size="sm"
                                            variant="link"
                                        >
                                            <>
                                                <FontAwesomeIcon
                                                    className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                    icon={faMegaphone}
                                                />
                                                <span>Submit Feedback</span>
                                            </>
                                        </Button>
                                    </li>
                                    <li className="tw-block md:tw-hidden lg:tw-block !tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="tel:1-800-558-2808"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faPhoneSquare}
                                            />
                                            <span>Call Imperial</span>
                                        </a>
                                    </li>
                                    <li className="!tw-leading-10 tw-mt-0">
                                        <a
                                            className="tw-text-gray-600"
                                            href="/contact-us"
                                        >
                                            <FontAwesomeIcon
                                                className="tw-w-10 !tw-h-5 tw-inline-block tw-text-center tw-mr-2 tw-text-gray-600"
                                                icon={faEnvelope}
                                            />
                                            <span>Contact Imperial</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tw-px-4 tw-border-t tw-pt-4">
                                <h4>Shop By</h4>
                                <ul
                                    className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0"
                                    data-e2e="shopByLinks"
                                >
                                    {user.isLoggedIn() && (
                                        <li className="tw-text-base tw-leading-10 tw-mt-0">
                                            <a
                                                className="!tw-text-gray-600"
                                                data-e2e="previouslyPurchasedLink"
                                                href="/browse/?FpurchasedBy_s_M=on"
                                            >
                                                Previously Purchased
                                            </a>
                                        </li>
                                    )}
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="featureProductsLink"
                                            href="/featured"
                                        >
                                            Featured Products
                                        </a>
                                    </li>
                                    {user.hasDeals() && (
                                        <li className="tw-text-base tw-leading-10 tw-mt-0">
                                            <a
                                                className="!tw-text-gray-600"
                                                href="/deals"
                                            >
                                                Deals
                                            </a>
                                        </li>
                                    )}
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="emergencyPreparednessLink"
                                            href="/emergency-preparedness"
                                        >
                                            Emergency Preparedness
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="bestSellersLink"
                                            href="/browse/?FshopBy_s_M=Best%20Seller"
                                        >
                                            Best Sellers
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="allBrandsLink"
                                            href="/brands"
                                        >
                                            All Brands
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="imperialBrandLink"
                                            href="/browse/?FshopBy_s_M=Imperial%20Brand"
                                        >
                                            Imperial Brand
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="newProductsLink"
                                            href="/newproducts"
                                        >
                                            New Products
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="ClearanceLink"
                                            href="/special/3769"
                                        >
                                            Clearance
                                        </a>
                                    </li>
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            data-e2e="wiperBladeFinderLink"
                                            href="/browse/vehicle-trailer-parts/wiper-blades-and-accessories/wiper-blades"
                                        >
                                            Wiper Blade Finder
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tw-px-4 tw-my-4 tw-border-t tw-pt-4">
                                <h4>Shop Talk</h4>
                                <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                    <li className="tw-text-base tw-leading-10 tw-mt-0">
                                        <a
                                            className="!tw-text-gray-600"
                                            href="/shop-talk"
                                        >
                                            Read Latest
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {!UserAgent.isReactNative(`5.3.0`) && (
                                <div className="tw-bg-gray-150 tw-flex tw-justify-between tw-p-4">
                                    Need offline mode?
                                    <a
                                        href={
                                            UserAgent.isiOS()
                                                ? `https://itunes.apple.com/us/app/imperial-supplies/id775108692?ls=1&mt=8`
                                                : `https://play.google.com/store/apps/details?id=com.imperial.ImperialApp`
                                        }
                                        target="_blank"
                                    >
                                        Download App
                                    </a>
                                </div>
                            )}
                            <div className="tw-px-4 tw-mb-4 tw-border-t tw-py-4">
                                {UserAgent.isReactNative(`5.3.0`) && (
                                    <Button
                                        block={true}
                                        className="tw-mb-4"
                                        onClick={() => {
                                            setTopMenuOpen(false);
                                            UserAgent.postNativeMessage({data: `true`, event: `setOfflineMode`});
                                            UserAgent.postNativeMessage({
                                                event: `navigate`,
                                                page: `Home`,
                                            });
                                        }}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        Use Offline Mode
                                    </Button>
                                )}
                                {user.isLoggedIn() && (
                                    <Button
                                        block={true}
                                        onClick={() => location.assign('/logout')}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        Log Out
                                    </Button>
                                )}
                            </div>
                            {UserAgent.isReactNative(`5.3.0`) && <div className="tw-ml-4">App Version: {getReactNativeVersion()}</div>}
                            <div className="tw-mb-[4.5rem] tw-pb-[4.5rem]" />
                        </div>
                    )}

                    <div
                        className={`tw-top-0 tw-ml-[400px] tw-w-full tw-h-0 tw-z-[2000] tw-overflow-y-auto tw-overflow-x-hidden tw-transition tw-duration-500 ${
                            showProductsMenu ? '!tw-ml-0 !tw-h-full' : ''
                        }`}
                    >
                        <div className="tw-border-t" />
                        <Button
                            className="!tw-w-[90%] tw-block tw-mx-4 tw-mt-6"
                            onClick={() => {
                                location.assign('/browse');
                            }}
                            size="sm"
                            variant="outline-secondary"
                        >
                            Shop All
                        </Button>
                        <div className="tw-p-4">
                            <ProductCategories
                                source="hamburger"
                                subMenu={true}
                            />
                        </div>
                        <div className="tw-mb-[4.5rem] tw-pb-2" />
                    </div>

                    {showAccountMenu && (
                        <>
                            <div className="tw-border-t" />
                            {user.isLoggedIn() && (
                                <div className="tw-px-4">
                                    <div className="tw-mb-6">
                                        <ul className="tw-text-gray-600 tw-text-base tw-font-normal tw-list-none tw-m-0 tw-p-0">
                                            <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                <h4>My Orders</h4>
                                                <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                    {user.canOrder() && (
                                                        <>
                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                <a
                                                                    className="tw-block tw-text-gray-600"
                                                                    href="/orders"
                                                                >
                                                                    Order Review
                                                                </a>
                                                            </li>
                                                            {!user.isPunchout && (
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-gray-600"
                                                                        href="/manageautoreorders"
                                                                    >
                                                                        Auto-Reorder Items
                                                                    </a>
                                                                </li>
                                                            )}
                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                <a
                                                                    className="tw-block tw-text-gray-600"
                                                                    href="/special-order"
                                                                >
                                                                    Special Order Review
                                                                </a>
                                                            </li>
                                                            {user.hasOrderApprovalAccess && (
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-gray-600"
                                                                        href="/orders/approval"
                                                                    >
                                                                        Order Approval
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </>
                                                    )}
                                                    {user.canOrder() && (
                                                        <>
                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                <a
                                                                    className="tw-block tw-text-gray-600"
                                                                    href="/returns?numMonths=6"
                                                                >
                                                                    My Returns
                                                                </a>
                                                            </li>
                                                            {user.hasActiveOrder() && !user.isPunchout ? (
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-gray-600"
                                                                        href={`/orders/${ordersService.currentOrderNumber}/checkout`}
                                                                    >
                                                                        Check Out Order #{ordersService.currentOrderNumber}
                                                                    </a>
                                                                </li>
                                                            ) : (
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-gray-600"
                                                                        href="#"
                                                                        onClick={() => {
                                                                            createNewOrder();
                                                                        }}
                                                                    >
                                                                        Create Order
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </>
                                                    )}
                                                </ul>
                                            </li>
                                            <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                <h4>Invoices & Statements</h4>
                                                <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                    {user.canInvoice() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/invoices"
                                                            >
                                                                Invoice Review
                                                            </a>
                                                        </li>
                                                    )}
                                                    {user.hasARAccess() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/ar"
                                                            >
                                                                Invoice Payment
                                                            </a>
                                                        </li>
                                                    )}
                                                    {user.hasSalesAccess() && user.canViewPrice() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/discounts"
                                                            >
                                                                Discount Review
                                                            </a>
                                                        </li>
                                                    )}
                                                    {user.hasJobCosting() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/jobcosting"
                                                            >
                                                                Repair Order Review
                                                            </a>
                                                        </li>
                                                    )}
                                                    {user.hasRepairOrder() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/ro"
                                                            >
                                                                Repair Orders
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                            <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                <h4>Reporting</h4>
                                                <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                    {user.hasSalesAccess() && user.canViewPrice && (
                                                        <>
                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                <a
                                                                    className="tw-block tw-text-gray-600"
                                                                    href="/spend-overview"
                                                                >
                                                                    Spend Overview Dashboard
                                                                </a>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tw-mb-6">
                                        <ul className="tw-text-gray-600 tw-text-base tw-font-normal tw-list-none tw-m-0 tw-p-0">
                                            <li className="tw-mt-5 tw-text-xl tw-pb-[4.5rem]">
                                                <h4>Account Management</h4>
                                                <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                    {user.canSwitchAccount() && (
                                                        <li
                                                            className="tw-text-base tw-font-light tw-leading-10 tw-mt-0"
                                                            style={{lineHeight: `20px`}}
                                                        >
                                                            <Button
                                                                className="!tw-text-gray-600 hover:!tw-underline"
                                                                onClick={() => {
                                                                    setTopMenuOpen(false);
                                                                    BodyScroll(true);
                                                                    setShowAccountFinderModal(true);
                                                                }}
                                                                size="sm"
                                                                variant="link"
                                                            >
                                                                <>
                                                                    <span>Switch Account</span>
                                                                    <br />
                                                                    <span className="tw-text-sm tw-font-normal tw-leading-[1.3]">
                                                                        Current:{' '}
                                                                        {user.activeAccount ? user.activeAccount : `No Account Selected`}
                                                                    </span>
                                                                </>
                                                            </Button>
                                                        </li>
                                                    )}
                                                    {user.isAccountLevel() && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/account-info"
                                                            >
                                                                Account Review
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                        <a
                                                            className="tw-block tw-text-gray-600"
                                                            href="/account#change-password"
                                                        >
                                                            Change Password
                                                        </a>
                                                    </li>
                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                        <a
                                                            className="tw-block tw-text-gray-600"
                                                            href="/email-preferences"
                                                        >
                                                            Email Preferences
                                                        </a>
                                                    </li>
                                                    {user.invoicePrefAccess && (
                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                            <a
                                                                className="tw-block tw-text-gray-600"
                                                                href="/invoice-delivery-preferences"
                                                            >
                                                                Invoice Delivery Preferences
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                        <a
                                                            className="tw-block tw-text-gray-600"
                                                            href="/payment-methods"
                                                        >
                                                            Payment Methods
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="tw-mb-[4.5rem]" />
                        </>
                    )}
                    {showMyShopMenu && (
                        <>
                            <div className="tw-border-t" />
                            <div className="tw-px-4">
                                <div className="tw-mb-6">
                                    <ul className="tw-text-gray-600 tw-text-base tw-font-normal tw-list-none tw-m-0 tw-p-0">
                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                    <a
                                                        className="tw-block tw-text-gray-600"
                                                        href="/my-shop/locations"
                                                    >
                                                        Locations
                                                    </a>
                                                </li>
                                                {UserAgent.isReactNative(`5.4.0`) && user.isFieldService() && (
                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                        <a
                                                            className="tw-block tw-text-gray-600"
                                                            href="#"
                                                            onClick={() => {
                                                                UserAgent.postNativeMessage({
                                                                    event: `navigate`,
                                                                    page: `ObsoleteItems`,
                                                                });
                                                            }}
                                                        >
                                                            Obsolete Items
                                                        </a>
                                                    </li>
                                                )}
                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                    <a
                                                        className="tw-block tw-text-gray-600"
                                                        href="/my-shop/inventory"
                                                    >
                                                        Inventory Items
                                                    </a>
                                                </li>
                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                    <a
                                                        className="tw-block tw-text-gray-600"
                                                        href="/my-shop/steel-equipment"
                                                    >
                                                        Steel Equipment
                                                    </a>
                                                </li>
                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                    <a
                                                        className="tw-block tw-text-gray-600"
                                                        href="/my-sds"
                                                    >
                                                        Safety Data Sheets
                                                    </a>
                                                </li>
                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                    <a
                                                        className="tw-block tw-text-gray-600"
                                                        href="/my-shop/backsheets"
                                                    >
                                                        Backsheets
                                                    </a>
                                                </li>
                                                {user.isFieldService() && (
                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                        <a
                                                            className="tw-block tw-text-gray-600"
                                                            href="/fs-photo-uploader"
                                                        >
                                                            Upload Photos
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tw-mb-[4.5rem]" />
                        </>
                    )}
                </FlyoutMenu>
            </div>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
            <AccountEntryModal
                onHide={() => setShowAccountEntryModal(false)}
                show={showAccountEntryModal}
            />
        </>
    );
};
