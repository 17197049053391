import * as React from 'react';
import * as prismicH from '@prismicio/helpers';

import PromotionTracker from '../../analytics/promotion-tracker/PromotionTracker';
import {CmsService} from '../../../client/cms/cms.service';
import {OurBenefitsCMS} from '../../our-benefits/our-benefits.class';
import {OurBenefitsStyles} from './OurBenefits.styles';
import {useEffect, useState} from 'react';
import {useService} from '../../react/ServiceContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import {Spinner} from '../../ui/Spinner/Spinner';

type OurBenefitsProps = {
    creativeSource: `Login Modal` | `Login Page` | `Create Account Page`;
    stacked?: boolean;
};

export const OurBenefits = ({creativeSource, stacked}: OurBenefitsProps) => {
    const [ourBenefits, setOurBenefits] = useState<OurBenefitsCMS>(null);
    const cmsService: CmsService = useService(`cmsService`);

    useEffect(() => {
        cmsService
            .getByType<OurBenefitsCMS>(`unlock_our_benefits_component`)
            .then((getByTypeRes) => {
                setOurBenefits(getByTypeRes);
            })
            .catch(() => {
                // Error silently
            });
    }, [cmsService]);

    /**
     * Returns template for "Our Benefits" link
     */
    const getOurBenefitsLink = () => (
        <a
            className="after:tw-absolute after:tw-inset-0 tw-no-underline"
            href={ourBenefits.lead_away_link}
        >
            <FontAwesomeIcon
                icon={faChevronCircleRight}
                className={`tw-text-base tw-leading-none ${ourBenefits.lead_away_icon} tw-text-gray-650 tw-mr-2`}
            />
            {ourBenefits.lead_away_text}
        </a>
    );

    /**
     * Template
     */
    return (
        <OurBenefitsStyles>
            {ourBenefits ? (
                <>
                    <div
                        className="tw-text-center tw-text-white tw-flex tw-justify-center tw-items-center tw-mb-4"
                        style={{
                            backgroundImage: `url('${ourBenefits.repeatable_background_image?.url}')`,
                            backgroundRepeat: `repeat`,
                            height: `75px`,
                            width: `100%`,
                        }}
                    >
                        <h3 className="tw-font-[industry-black] tw-relative">
                            {ourBenefits.floating_copy}
                            <div className="headerUnderline" />
                        </h3>
                    </div>
                    <div className={`${stacked ? 'tw-w-full' : 'tw-grid tw-grid-cols-2 tw-gap-4 tw-w-full tw-align-items-center'}`}>
                        {ourBenefits.value_add_blocks?.map((vaBlock, index) => (
                            <div
                                className={`tw-w-full ${stacked ? 'tw-mb-4' : ''}`}
                                key={index}
                            >
                                <div className="tw-border tw-h-full tw-p-2">
                                    <div
                                        className="tw-text-gray-400 tw-h-full tw-text-center tw-items-center tw-justify-center tw-flex [&_p]:tw-mb-0"
                                        dangerouslySetInnerHTML={{__html: prismicH.asHTML(vaBlock.value_add_block)}}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="tw-w-full">
                            <div className="tw-border tw-h-full tw-p-2">
                                <div className="tw-relative tw-text-gray-400 tw-h-full tw-text-center tw-items-center tw-justify-center tw-flex [&_p]:tw-mb-0">
                                    {ourBenefits.lead_away_promotion_id ? (
                                        <PromotionTracker
                                            as="span"
                                            promotion={{
                                                creative_name: `${ourBenefits.lead_away_promotion_creative}${creativeSource}`,
                                                creative_slot: ourBenefits.value_add_blocks.length + 1,
                                                promotion_id: ourBenefits.lead_away_promotion_id,
                                                promotion_name: ourBenefits.lead_away_promotion_name,
                                            }}
                                        >
                                            {getOurBenefitsLink()}
                                        </PromotionTracker>
                                    ) : (
                                        getOurBenefitsLink()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </OurBenefitsStyles>
    );
};
