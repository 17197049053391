import {Observable, ReplaySubject} from 'rxjs';

import {FetchService} from '../fetch/fetch.service';
import {GaCartItem, gaRemoveFromCart, removeOrderFromGaAddToCartItemListNames} from '../ga/ga-ecommerce.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {ImpUrl} from '../imp-url/imp-url.class';
import {OrderHeader} from '../../shared/orders/order-header.class';
import {SavedForLaterItem} from '../../shared/orders/saved-for-later/saved-for-later-item.class';
import {ShareItemRes} from '../../shared/items/item.class';
import {ShareLinkParams} from '../../shared/share-link/share-link.class';
import {StandardJSONResponse} from '../../shared/tools/AS.types';
import {SubmitPunchoutRes} from '../../server/punchout/punchout-session.class';
import {UsersService} from '../users/users.service';
import {User} from '../../shared/users/user.class';
import {
    ApprovalListRes,
    ApproveOrderRes,
    Backorder,
    CheckRestrictedItemsRes,
    CreateOrderRes,
    DeleteOrderRes,
    Order,
    OrderLine,
    OrderToApprove,
    SavedForLaterAction,
    ShareOrderItemsListIdRes,
    SplitOrderRes,
    SubmitQuoteParams,
    UpdateOrderParams,
    UpdateSavedForLaterItemsRes,
} from '../../shared/orders/order.class';

export class OrdersService {
    public currentOrder$: Observable<Order>;
    public savedForLaterItems$: Observable<SavedForLaterItem[]>;
    private _addStockStatus: boolean;
    private _currentOrder: Order = null;
    private _currentOrderSubject: ReplaySubject<Order>;
    private _currentUser: User = null;
    private _savedForLaterItemsSubject: ReplaySubject<SavedForLaterItem[]>;

    constructor(
        private _fetchService: FetchService,
        private _usersService: UsersService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
        this._addStockStatus = ImpUrl.onOrderDetailPage;
        this._setupObservables();
    }

    /**
     * Gets a list of orders for approval from the API
     */
    public approvalList() {
        return this._fetchService.get<ApprovalListRes>(`/api/orders/approvalList`);
    }

    /**
     * Processes approval of multiple orders
     * @param emailCopy
     * @param ordersToApprove
     */
    public approveOrders(emailCopy: boolean, ordersToApprove: OrderToApprove[]) {
        return this._fetchService.post<ApproveOrderRes[]>(`/api/orders/approveOrders`, {emailCopy, orders: ordersToApprove});
    }

    /**
     * Performs checkout of provided orderNumber (without CheckoutForm parameters)
     * @param orderNumber
     */
    public checkout(orderNumber: string) {
        return this._fetchService.post<string>(`/api/orders/checkout/${orderNumber}`, {}, true);
    }

    /**
     * Checks provided order for restricted items (and removes them)
     * @param orderNumber - Order number to check
     */
    public checkRestrictedItems(orderNumber: string) {
        return this._fetchService.get<CheckRestrictedItemsRes>(`/api/orders/checkRestrictedItems/${orderNumber}`);
    }

    /**
     * Creates a new, empty order
     */
    public createOrder() {
        return this._fetchService.post<CreateOrderRes>(`/api/Orders`);
    }

    /**
     * Returns user's active order number
     */
    public get currentOrderNumber() {
        // Prefer this._currentOrder as most current
        if (this._currentOrder && this._currentOrder.orderNbr) {
            return this._currentOrder.orderNbr;
        }

        // Else consider the session's activeOrder
        if (this._currentUser.activeOrder) {
            return this._currentUser.activeOrder;
        }

        // Default to an empty string
        return ``;
    }

    /**
     * Deletes backorder
     * @param orderNumber - Order number to delete
     */
    public deleteBackorder(orderNumber: string) {
        return this._fetchService.delete<StandardJSONResponse>(`/api/orders/deleteBackorder/${orderNumber}`);
    }

    /**
     * Delete the indicated order
     * @param orderNumber - Order to delete
     * @param isQuote - True if order is a quote
     * @param orderLines - Deleted orderLines for analytics
     */
    public deleteOrder(orderNumber: string, isQuote: boolean, orderLines: OrderLine[]) {
        return new Promise<DeleteOrderRes>((resolve, reject) => {
            this._fetchService
                .delete<DeleteOrderRes>(`/api/orders/deleteOrder/${orderNumber}?isQuote=${isQuote || ''}`)
                .then((deleteOrderRes) => {
                    // Record analytics
                    const gaCartItems: GaCartItem[] = orderLines.map((orderLine) => {
                        return {
                            dimension16: orderNumber,
                            item_id: orderLine.item,
                            item_list_name: ``,
                            price: orderLine.pricePK,
                            quantity: orderLine.unitsOrdered,
                        };
                    });
                    gaRemoveFromCart(gaCartItems);
                    removeOrderFromGaAddToCartItemListNames(orderNumber);

                    // Return result
                    resolve(deleteOrderRes);
                })
                .catch((deleteOrderErr: ImpError) => {
                    reject(deleteOrderErr);
                });
        });
    }

    /**
     * Emails specified order to user
     * @param orderNumber - Order to email
     */
    public emailOrder(orderNumber: string): Promise<string> {
        return this._fetchService.get<string>(`/api/orders/emailOrder/${orderNumber}`, undefined, true);
    }

    /**
     * Get detail for backorder
     * @param orderNumber - Order number
     */
    public getBackorder(orderNumber: string) {
        return this._fetchService.get<Backorder>(`/api/orders/getBackorder/${orderNumber}`);
    }

    /**
     * Returns currentOrder, loading if necessary
     */
    public getCurrentOrder() {
        // eslint-disable-next-line
        return new Promise<Order>((resolve, reject) => {
            if (this._currentOrder) {
                resolve(this._currentOrder);
            } else if (this.currentOrderNumber) {
                return this.loadOrder(this.currentOrderNumber)
                    .then(() => {
                        resolve(this._currentOrder);
                    })
                    .catch((loadOrderErr: ImpError) => {
                        reject(loadOrderErr);
                    });
            } else {
                resolve(null);
            }
        });
    }

    /**
     * Returns an array of editable orders
     * @param orderList - List of orders to filter
     */
    public static getEditableOrders(orderList: OrderHeader[]) {
        const editableOrders: OrderHeader[] = [];
        if (orderList && Array.isArray(orderList)) {
            orderList.forEach((orderHeader) => {
                if (Order.isEditable(orderHeader)) {
                    editableOrders.push(orderHeader);
                }
            });
        }
        return editableOrders;
    }

    /**
     * Gets requested order data (without workflow)
     * @param orderNbr - Order to get the order detail for
     */
    public getOrder(orderNbr: string) {
        return new Promise<Order>((resolve, reject) => {
            this._fetchService
                .get<Order>(`/api/orders/getOrder/${orderNbr}`)
                .then((getOrderRes) => {
                    resolve(new Order(getOrderRes));
                })
                .catch((getOrderErr: ImpError) => {
                    reject(getOrderErr);
                });
        });
    }

    /**
     * Gets list of all user's orders
     */
    public getOrders() {
        return this._fetchService.get<OrderHeader[]>(`/api/Orders/orderListing`);
    }

    /**
     * Retrieves listID for shared order
     * @param orderNumber - Order to share
     */
    public getShareOrderListId(orderNumber: string) {
        return this._fetchService.get<ShareOrderItemsListIdRes>(`/api/order/share/${orderNumber}`);
    }

    /**
     * Gets requested order data (with workflow)
     * @param orderNbr - Order to get the order detail for
     */
    public loadOrder(orderNbr: string) {
        return new Promise<Order>((resolve, reject) => {
            this._getOrder(orderNbr)
                .then((getOrderRes) => {
                    // Clear currentOrder and return null if !editable
                    if (!Order.isEditable(getOrderRes)) {
                        this._clearCurrentOrder();
                        resolve(null);
                        return;
                    }

                    // Else set current order and notify listeners
                    this.setCurrentOrder(getOrderRes);

                    // And return getOrderRes
                    resolve(getOrderRes);
                })
                .catch((getOrderErr: ImpError) => {
                    // Don't continue to try work with a problematic order
                    if (this._currentOrder && this._currentOrder.ordrNbr === orderNbr) {
                        this._clearCurrentOrder();
                    }

                    // Clear currentOrder, if error is on the initial load
                    if (!this._currentOrder) {
                        this._clearCurrentOrder();
                    }
                    reject(getOrderErr);
                });
        });
    }

    /**
     * Sets currentOrder to the provided Order
     * @param order - order to set as currentOrder
     */
    public setCurrentOrder(order: Order) {
        this._currentOrder = order;
        this._currentOrderSubject.next(this._currentOrder);
    }

    /**
     * Shares item
     * @param shareOrderParams
     */
    public shareOrder(shareOrderParams: ShareLinkParams) {
        return this._fetchService.post<ShareItemRes>(`/api/orders/shareOrder`, shareOrderParams);
    }

    /**
     * Creates new order from provided items
     * @param order - Order to create split from
     * @param splitItems - Items for new order
     */
    public splitOrder(order: string, splitItems: string[]) {
        return this._fetchService.post<SplitOrderRes>(`/api/orders/splitOrder`, {
            order,
            splitItems,
        });
    }

    /**
     * Submits specified punchout order
     * @param orderNumber
     */
    public submitPunchout(orderNumber: string) {
        return this._fetchService.post<SubmitPunchoutRes>(`/orders/${orderNumber}/checkout/submit-punchout`);
    }

    /**
     * Submits provided order as a quote
     * @param submitQuoteParams
     */
    public submitQuote(submitQuoteParams: SubmitQuoteParams) {
        return this._fetchService.post<StandardJSONResponse>(`/api/orders/submitQuote`, submitQuoteParams);
    }

    /**
     * Updates order header data
     * @param orderNumber - Order number to update
     * @param updateOrderParams - Updated order info
     */
    public updateOrder(orderNumber: string, updateOrderParams: UpdateOrderParams): Promise<string> {
        return new Promise((resolve, reject) => {
            // Append order number
            updateOrderParams.order = orderNumber;
            this._fetchService
                .post<string>(`/api/Orders/updateOrder`, updateOrderParams, true)
                .then((updateOrderRes) => {
                    // Refresh currentOrder
                    this.loadOrder(orderNumber)
                        .then(() => {
                            resolve(updateOrderRes);
                        })
                        .catch((loadOrderErr: ImpError) => {
                            reject(loadOrderErr);
                        });
                })
                .catch((updateOrderErr: ImpError) => {
                    reject(updateOrderErr);
                });
        });
    }

    /**
     * Updates the users saved for later items
     * @param item
     * @param action
     * @param qty
     */
    public updateSavedForLaterItems(item: string, action: SavedForLaterAction, qty?: string) {
        return new Promise<UpdateSavedForLaterItemsRes>((resolve, reject) => {
            this._fetchService
                .post<UpdateSavedForLaterItemsRes>(`/api/Orders/updateSavedForLaterItems`, {
                    action,
                    item,
                    qty: qty || ``,
                })
                .then((updateSavedForLaterItemsRes) => {
                    // Refresh savedForLaterItems$
                    this._getSavedForLaterItems()
                        .then((getSavedForLaterItemsRes) => {
                            this._savedForLaterItemsSubject.next(getSavedForLaterItemsRes);
                            resolve(updateSavedForLaterItemsRes);
                        })
                        .catch((getSavedForLaterItemsErr: ImpError) => {
                            reject(getSavedForLaterItemsErr);
                        });
                })
                .catch((updateSavedForLaterItemsErr: ImpError) => {
                    reject(updateSavedForLaterItemsErr);
                });
        });
    }

    /**
     * Clears currentOrder
     */
    private _clearCurrentOrder() {
        this._currentOrder = null;
        this._currentOrderSubject.next(this._currentOrder);
        this._currentUser.clearOrder();
    }

    /**
     * Performs network call to get orderDetail for specified order
     * @param orderNbr - Order number to get the detail for
     */
    private _getOrder(orderNbr: string) {
        return new Promise<Order>((resolve, reject) => {
            this._fetchService
                .get<Order>(`/api/Orders/${orderNbr}${this._addStockStatus ? '?addStockStatus=true' : ''}`)
                .then((getOrderRes) => {
                    resolve(new Order(getOrderRes));
                })
                .catch((getOrderErr: ImpError) => {
                    reject(getOrderErr);
                });
        });
    }

    /**
     * Gets the user's saved for later items
     */
    private _getSavedForLaterItems() {
        return this._fetchService.get<SavedForLaterItem[]>(`/api/Orders/getSavedForLaterItems`);
    }

    /**
     * Sets up observables
     * @private
     */
    private _setupObservables() {
        // currentOrder$
        this._currentOrderSubject = new ReplaySubject<Order>(1);
        this.currentOrder$ = this._currentOrderSubject.asObservable();

        // savedForLaterItems$
        this._savedForLaterItemsSubject = new ReplaySubject<SavedForLaterItem[]>(1);
        this.savedForLaterItems$ = this._savedForLaterItemsSubject.asObservable();
    }
}
