import * as React from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {faExclamationTriangle, faListAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {BodyScroll} from '../../ui/ScrollHandler';
import {DisplayCurrencyFormatted} from '../../orders/DisplayCurrencyFormatted/DisplayCurrencyFormatted';
import {FlyoutMenuStyles} from '../../ui/Flyouts/FlyoutMenu.styles';
import {FlyoutMenu} from '../../ui/Flyouts/FlyoutMenu';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ImpUrl} from '../../../client/imp-url/imp-url.class';
import {OrderHeader} from '../../orders/order-header.class';
import {OrderMenuContainer} from './OrderMenuFlyout.style';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {Radio} from '../../ui/forms/Radio/Radio';
import {useOrder} from '../../lib/hooks/use-order';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import {EmptyState} from '../../ui/EmptyState';
import Button from '../../ui/Buttons/Button';

interface OrderMenuFlyoutProps {
    user: User;
}

export const OrderMenuFlyout = ({user}: OrderMenuFlyoutProps) => {
    const [collapsed, setCollapsed] = useState(``);
    const [errorMessage, setErrorMessage] = useState<string | JSX.Element>(``);
    const [getOrdersLoading, setGetOrdersLoading] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [orders, setOrders] = useState<OrderHeader[]>(null);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const componentName = `OrderMenuFlyout`;
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);
    const {getOrders, orderErrors} = useOrder({componentName});

    /**
     * Creates new order
     */
    const createNewOrder = () => {
        gaLegacyCustomEvent({eventAction: `create_new_order`, eventCategory: `Ecommerce`, eventLabel: `orders_menu`});
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                setMenuOpen(true);
                setErrorMessage(createOrderErr.message);
            },
        });
    };

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAction(componentName, `createOrder`)) {
            createNewOrder();
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Display any useOrder related errors
    useEffect(() => {
        if (orderErrors?.length > 0) {
            setErrorMessage(orderErrors[0].message);
        }
    }, [orderErrors]);

    // Retrieve orders when menu displayed
    useEffect(() => {
        if (getOrders && !ordersLoaded && menuOpen) {
            setGetOrdersLoading(true);
            getOrders((getOrdersRes) => {
                setOrders(OrdersService.getEditableOrders(getOrdersRes));
                setGetOrdersLoading(false);
                setOrdersLoaded(true);
            });
        }
    }, [menuOpen]);

    /**
     * Loads and sets active order
     * @param orderNumber
     */
    const selectActiveOrder = (orderNumber: string) => {
        ordersService
            .loadOrder(orderNumber)
            .then((loadOrderRes) => {
                if (loadOrderRes) {
                    // If onOrderDetailPage, nav to active order
                    if (ImpUrl.onOrderDetailPage) {
                        location.assign(`/orders/${loadOrderRes.ordrNbr}/edit`);
                        return;
                    }

                    // Reload page if user switches accounts
                    if (loadOrderRes.account !== user.activeAccount) {
                        location.reload();
                        return;
                    }
                }

                // Else remain on page and close component
                setMenuOpen(false);
                BodyScroll(true);
            })
            .catch((loadOrderErr: ImpError) => {
                setErrorMessage(loadOrderErr.message);
            });
    };

    /**
     * Toggles display of orders menu
     */
    const toggleOrdersMenu = () => {
        // Work with HeaderOrdersMenuComponent if logged in
        if (user.isLoggedIn()) {
            setMenuOpen(true);
            BodyScroll(false);
            return;
        }

        // Else trigger createOrPickOrderNavOrderDetail workflow
        ordersWorkflow.createOrPickOrderNavOrderDetail(componentName);
    };

    /**
     * Template
     */
    return (
        <>
            <OrderMenuContainer>
                <a
                    className="tw-flex tw-items-center tw-cursor-pointer"
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        toggleOrdersMenu();
                    }}
                    href="/orders"
                >
                    <FontAwesomeIcon
                        className="tw-mr-2 tw-text-gray-600"
                        icon={faListAlt}
                        size="lg"
                    />
                    <span className="tw-text-gray-600 tw-text-base tw-mb-0">Orders</span>
                </a>
            </OrderMenuContainer>
            <FlyoutMenuStyles>
                <FlyoutMenu
                    data-e2e="manageOrderDropdown"
                    onClose={() => {
                        setMenuOpen(false);
                        BodyScroll(true);
                    }}
                    show={menuOpen}
                    title="Manage Orders"
                >
                    <>
                        {getOrdersLoading ? (
                            <EmptyState message="Loading..." />
                        ) : errorMessage ? (
                            <EmptyState
                                message={
                                    <div>
                                        <div className="tw-flx tw-flex-col">
                                            <div>We're sorry an error occurred</div>
                                            <div>{errorMessage}</div>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    location.reload();
                                                }}
                                            >
                                                Try again?
                                            </a>
                                        </div>
                                    </div>
                                }
                            />
                        ) : !user.activeAccount ? (
                            <EmptyState
                                message={
                                    <div>
                                        You must&nbsp;
                                        <a
                                            className="tw-cursor-pointer tw-text-blue-200"
                                            onClick={() => {
                                                setMenuOpen(false);
                                                BodyScroll(true);
                                                setShowAccountFinderModal(true);
                                            }}
                                        >
                                            select an account
                                        </a>
                                        &nbsp;to see your open orders
                                    </div>
                                }
                            />
                        ) : !orders?.length ? (
                            <EmptyState message="You do not have any open orders." />
                        ) : (
                            <div className="tw-m-4">
                                <table className="tw-w-full tw-mb-4 tw-text-color tw-indent-0 tw-border-collapse align-columns-middle">
                                    <thead>
                                        <tr>
                                            <td
                                                className="tw-w-2.5 tw-align-middle tw-p-3"
                                                style={{width: `10px`}}
                                            ></td>
                                            <td
                                                className="tw-text-left tw-p-3 tw-w-[120px]"
                                                style={{width: `120px`}}
                                            >
                                                Order #
                                            </td>
                                            <td
                                                className="tw-text-left tw-p-3 tw-w-[120px]"
                                                style={{width: `120px`}}
                                            >
                                                PO #
                                            </td>
                                            {user.canViewPrice() && <td className="tw-text-right tw-p-3">Amount</td>}
                                            <td className="tw-align-middle tw-p-3" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders?.map((openOrder) => (
                                            <React.Fragment key={openOrder.ordrNbr}>
                                                <tr>
                                                    <td className="tw-align-middle tw-px-3 tw-pt-3 tw-border-t">
                                                        <div className="tw-relative tw-align-middle tw-z-[1] tw-block tw-min-h-[1.5rem] tw-pl-6">
                                                            <Radio
                                                                alias={openOrder.ordrNbr}
                                                                ariaLabel="Make Active Order"
                                                                checked={ordersService.currentOrderNumber === openOrder.ordrNbr}
                                                                className="tw-mb-0"
                                                                labelClass="pb-0"
                                                                name="selectActiveOrder"
                                                                onChange={(e) => {
                                                                    setMenuOpen(false);
                                                                    BodyScroll(true);
                                                                    gaLegacyCustomEvent({
                                                                        eventAction: `orders_menu_select_order`,
                                                                        eventCategory: `Open Orders`,
                                                                    });
                                                                    selectActiveOrder(e.currentTarget.value);
                                                                }}
                                                                value={openOrder.ordrNbr}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="tw-align-middle tw-p-3 tw-border-t tw-text-left">
                                                        <a
                                                            className="tw-cursor-pointer tw-text-blue-200"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                gaLegacyCustomEvent({
                                                                    eventAction: `orders_menu_view_order`,
                                                                    eventCategory: `Open Orders`,
                                                                });
                                                                location.assign(`/orders/${openOrder.ordrNbr}/edit`);
                                                            }}
                                                        >
                                                            {openOrder.ordrNbr}
                                                        </a>
                                                        {openOrder.status === `Order Declined` && (
                                                            <div className="tw-text-red-500">
                                                                <FontAwesomeIcon
                                                                    className="tw-mr-1"
                                                                    icon={faExclamationTriangle}
                                                                />
                                                                Declined
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="tw-align-middle tw-p-3 tw-border-t maskPII">{openOrder.po}</td>
                                                    {user.canViewPrice() && (
                                                        <td className="tw-align-middle tw-p-3 tw-border-t maskPII tw-text-right">
                                                            <DisplayCurrencyFormatted amount={openOrder.amount} />
                                                        </td>
                                                    )}
                                                    <td className="tw-align-middle tw-p-3 tw-border-t maskPII">
                                                        {collapsed !== openOrder.ordrNbr ? (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={faAngleDown}
                                                                    onClick={() => {
                                                                        setCollapsed(openOrder.ordrNbr);
                                                                    }}
                                                                    size="lg"
                                                                    style={{cursor: `pointer`, verticalAlign: `middle`}}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={faAngleUp}
                                                                    onClick={() => {
                                                                        setCollapsed(``);
                                                                    }}
                                                                    size="lg"
                                                                    style={{cursor: `pointer`, verticalAlign: `middle`}}
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    className={collapsed === openOrder.ordrNbr ? `` : `tw-hidden`}
                                                    id={`orderDetail__${openOrder.ordrNbr}`}
                                                >
                                                    <td className="tw-align-middle tw-p-3 tw-border-t" />
                                                    <td
                                                        className="tw-align-middle tw-p-3 tw-border-t tw-text-left"
                                                        colSpan={4}
                                                    >
                                                        <div className="tw-mb-2">Order Dt: {openOrder.ordrDt}</div>
                                                        <div className="tw-mb-2 maskPII">Attn: {openOrder.attn}</div>
                                                        <div className="tw-mb-2">City: {openOrder.city}</div>
                                                        {openOrder.location && <div className="tw-mb-2">Site ID: {openOrder.location}</div>}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className="tw-grid tw-grid-cols-1 tw-mx-4 tw-pb-4">
                            <Button
                                className="tw-px-2 tw-w-full"
                                data-e2e="createNewOrderLink"
                                onClick={() => {
                                    gaLegacyCustomEvent({eventAction: `orders_menu_create_new_order`, eventCategory: `Open Orders`});
                                    setMenuOpen(false);
                                    createNewOrder();
                                }}
                                size="lg"
                                variant="primary"
                            >
                                Create New Order
                            </Button>
                            <Button
                                className="tw-px-2 tw-w-full tw-my-4"
                                onClick={() => {
                                    gaLegacyCustomEvent({
                                        eventAction: `view_all_orders`,
                                        eventCategory: `Ecommerce`,
                                        eventLabel: `orders_menu`,
                                    });
                                    location.assign('/orders');
                                }}
                                variant="outline-primary"
                            >
                                View All Orders
                            </Button>
                        </div>
                    </>
                </FlyoutMenu>
            </FlyoutMenuStyles>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
        </>
    );
};
