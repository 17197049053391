import * as React from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Slice} from '../../cms/cms.types';
import Button from '../../ui/Buttons/Button';

export interface NavSegmentProps {
    navSegment?: Slice;
    onClick: (segmentIndex: number) => void;
    openSegmentIndex: number;
    segmentIndex: number;
}

export const NavSegment = ({navSegment, onClick, segmentIndex, openSegmentIndex}: NavSegmentProps) => {
    return (
        <div className="lg:tw-mt-4">
            <div className="tw-border-b tw-border-gray-150 tw-bg-gray-600 tw-h-12 tw-leading-[48px] tw-px-3 lg:tw-leading-normal lg:tw-px-0 tw-mb-0 lg:!tw-bg-gray-100 lg:!tw-border-b-0 lg:tw-h-auto">
                <Button
                    className="tw-uppercase !tw-font-extrabold !tw-text-lg !tw-text-white lg:!tw-text-gray-700 tw-w-full"
                    onClick={() => {
                        onClick(segmentIndex);
                    }}
                    size="sm"
                    variant="link"
                >
                    <div className="tw-flex tw-justify-between">
                        {navSegment.primary.footer_segment_header}
                        <span className="tw-block lg:tw-hidden">
                            <FontAwesomeIcon
                                suppressHydrationWarning
                                icon={openSegmentIndex === segmentIndex ? faAngleUp : faAngleDown}
                                size="lg"
                            />
                        </span>
                    </div>
                </Button>
            </div>
            <ul
                className={`tw-mt-4 tw-p-0 tw-list-none tw-mx-3 lg:!tw-mx-0 tw-mb-3${
                    openSegmentIndex === segmentIndex ? ' tw-block' : ' tw-hidden'
                } lg:!tw-block`}
            >
                {navSegment.items.map((navLinks, itemIndex) => (
                    <li
                        className="tw-pb-1 tw-leading-8 [&_.heading]:tw-pb-1.5"
                        key={itemIndex}
                    >
                        <a
                            className="tw-text-gray-700"
                            dangerouslySetInnerHTML={{__html: navLinks.link_title}}
                            data-binding={navLinks.data_binding}
                            href={navLinks.link_url}
                            id={navLinks.link_id}
                            rel={navLinks.seo_nofollow ? 'nofollow' : ''}
                            target={navLinks.link_target ? '_blank' : ''}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
