import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AccountFinderDistrict} from './AccountFinderDistrict';
import {AccountFinderViewProps, filterVisible} from './AccountFinderView';
import {User} from '../../users/user.class';
import Button from '../../ui/Buttons/Button';

interface AccountFinderDivisionProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    selectAccount: (accountNum: string, contactID: string, returnUrlParam?: string) => void;
    setAccountKeys: Dispatch<SetStateAction<string[]>>;
    type?: `parent` | `division` | `district`;
    user: User;
}

export const AccountFinderDivision = ({
    account,
    accountKeys,
    accountOnlyMode,
    selectAccount,
    setAccountKeys,
    type,
    user,
}: AccountFinderDivisionProps) => {
    return (
        <div className={account.hidden ? `tw-hidden` : undefined}>
            <div className={`tw-flex tw-justify-between ${type === 'parent' ? 'tw-bg-gray-200' : 'tw-bg-gray-150'} tw-border-t tw-p-4`}>
                <div className="tw-flex tw-items-center">
                    {type !== 'parent' && (
                        <div className="tw-mr-4">
                            {account.accounts.filter(({hidden}) => !hidden).length > 0 && (
                                <FontAwesomeIcon
                                    icon={accountKeys.indexOf(account.account) >= 0 ? faAngleDown : faAngleUp}
                                    size="xl"
                                    className="pointer"
                                    onClick={() => {
                                        if (accountKeys.indexOf(account.account) === -1) {
                                            setAccountKeys([...accountKeys, account.account]);
                                        } else {
                                            setAccountKeys(accountKeys.filter((accountKey) => accountKey !== account.account));
                                        }
                                    }}
                                />
                            )}
                        </div>
                    )}
                    <div className="tw-flex tw-flex-col">
                        <div className="tw-text-xl !tw-mb-0 tw-font-bold !tw-capitalize">{account.name?.toLowerCase()}</div>
                        <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">
                            {account.account} {account.hidden}
                        </div>
                    </div>
                </div>
                <div>
                    {user.activeAccount === account.account && <>Logged In</>}
                    {user.activeAccount !== account.account && !accountOnlyMode && (
                        <Button
                            size="sm"
                            variant="secondary"
                            className="tw-py-1.5 tw-px-3"
                            onClick={() => selectAccount(account.account, account.contact)}
                        >
                            Log In
                        </Button>
                    )}
                </div>
            </div>
            <div>
                {account.accounts &&
                    account.accounts.filter(filterVisible()).map(
                        (district) =>
                            district.type === `District` && (
                                <AccountFinderDistrict
                                    account={district}
                                    accountKeys={accountKeys}
                                    accountOnlyMode={accountOnlyMode}
                                    division={account.account}
                                    key={district.account}
                                    selectAccount={selectAccount}
                                    setAccountKeys={setAccountKeys}
                                    user={user}
                                />
                            ),
                    )}
            </div>
        </div>
    );
};
