import * as React from 'react';
import {faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {UserStateService} from '../../../client/users/user-state.service';
import {useService} from '../../react/ServiceContext';
import {Badge} from '../../ui/Badges/Badge';

interface HeaderOrderButtonProps {
    color?: `dark` | `light`;
    hasActiveOrder?: boolean;
    isScanner?: boolean;
    numOrderLines?: number;
}

export const HeaderOrderButton = ({color = `dark`, hasActiveOrder, isScanner, numOrderLines}: HeaderOrderButtonProps) => {
    const [hasActiveOrderState, setHasActiveOrderState] = useState(hasActiveOrder);
    const [numOrderLinesState, setNumOrderLinesState] = useState(numOrderLines);
    const componentName = `HeaderOrderButton`;
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAction(componentName, `createOrder`)) {
            ordersWorkflow.createOrPickOrderNavOrderDetail(componentName);
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Subscribe to currentOrder$ for updates
    useEffect(() => {
        ordersService.currentOrder$.subscribe({
            next: (currentOrder) => {
                setHasActiveOrderState(!!currentOrder);
                setNumOrderLinesState(currentOrder ? currentOrder.ordrLines : undefined);
            },
        });
    }, [ordersService]);

    /**
     * Navigates to active order
     */
    const navActiveOrder = () => {
        ordersWorkflow.createOrPickOrderNavOrderDetail(componentName);
    };

    /**
     * Template
     */
    return (
        <>
            <button
                aria-label="Cart Icon"
                className="tw-cursor-pointer tw-flex tw-relative tw-justify-end tw-p-3 tw-text-gray-650 tw-bg-transparent"
                id="impHeader__orderBtn"
                onClick={navActiveOrder}
            >
                <FontAwesomeIcon
                    className={`${color === 'dark' ? 'tw-text-gray-600' : 'tw-text-gray-150'} tw-absolute`}
                    icon={faShoppingCart}
                    size="lg"
                />
                <span className="tw-relative tw-top-[-14px] tw-left-[8px] h-[22px]">
                    {hasActiveOrderState && !(isScanner && numOrderLinesState === 0) ? (
                        <Badge
                            cartBadge={true}
                            data-e2e="cartBadgeBtn"
                            noMargin={true}
                            variant="danger"
                        >
                            {numOrderLinesState}
                        </Badge>
                    ) : (
                        <div className="tw-w-[26px]">&nbsp;</div>
                    )}
                </span>
            </button>
        </>
    );
};
