import * as React from 'react';
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {Alerts} from '../../ui/Alerts/Alerts';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {Input} from '../../ui/forms/Input/Input';
import {MROMatch} from '../../search/search-suggestions/search-suggestions.class';
import {MRORequestParams} from '../../search/search-result/search-result.class';
import {SearchService} from '../../../client/search/search.service';
import {showChatWindow} from '../../contacts/contact.functions';
import {TextArea} from '../../ui/forms/TextArea/TextArea';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';

interface MROResultProps {
    mroMatch: MROMatch;
}

export const MROResult = ({mroMatch}: MROResultProps) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showSuccess, setShowSuccess] = useState(false);
    const searchService: SearchService = useService(`searchService`);

    // Setup form
    const schema = yup
        .object({
            mroComment: yup.string().optional().max(900).label(`Comment`),
            mroEmail: yup.string().required().max(45).email().label(`E-Mail Address`),
            mroName: yup.string().required().max(45).label(`Name`),
            mroPhone: yup.string().required().label(`Phone Number`),
            mroQty: yup.string().optional().label(`Desired Order Qty`),
        })
        .required();
    const form = useForm({resolver: yupResolver(schema)});
    const formValues = form.watch();

    /**
     * Submits form
     */
    const submitForm = () => {
        setShowSuccess(false);
        setErrorMessage(null);
        const mroRequestParams: MRORequestParams = {
            comment: formValues.mroComment,
            email: formValues.mroEmail,
            itemID: mroMatch.mroItem,
            name: formValues.mroName,
            orderQuantity: formValues.mroQty,
            phone: formValues.mroPhone,
        };
        searchService
            .sendMRORequest(mroRequestParams)
            .then(() => {
                setShowSuccess(true);
            })
            .catch((sendMRORequestErr: ImpError) => {
                setErrorMessage(sendMRORequestErr.message);
            });
    };

    /**
     * Template
     */
    return (
        <div className="tw-w-full tw-relative tw-px-2.5">
            <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-0 lg:tw-gap-4">
                <div className="tw-relative tw-w-full tw-col-span-1 tw-mb-4">
                    <div className="tw-border-b tw-mb-4 -tw-mx-4 md:tw-mx-0">
                        <h5 className="tw-text-black tw-p-4 tw-text-sm tw-font-bold tw-leading-[1.3] tw-uppercase tw-mb-0 lg:tw-normal-case lg:tw-opacity-60 lg:tw-font-normal lg:tw-text-xl lg:tw-leading-[1.2]">
                            Exact Match
                        </h5>
                    </div>
                    <div className="tw-grid tw-grid-cols-12 lg:tw-grid-cols-1">
                        <div className="-tw-mr-2.5 lg:tw-mr-0 tw-relative tw-w-full tw-col-span-3 md:tw-col-span-2 lg:tw-col-span-1 tw-text-center">
                            <img
                                alt={mroMatch.description}
                                className="tw-p-4 md:tw-p-0"
                                height="75"
                                src={`/mro-image/${mroMatch.mroItem}`}
                                width="75"
                            />
                        </div>
                        <div className="tw-relative tw-w-full tw-col-span-9 md:tw-col-span-10 lg:tw-col-span-1 tw-pt-4 md:tw-pt-0">
                            <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">{mroMatch.mroItem}</div>
                            <div>{mroMatch.description}</div>
                        </div>
                    </div>
                </div>
                <div className="tw-col-span-1 lg:tw-col-span-3 tw-relative tw-w-full">
                    <FormProvider {...form}>
                        <form
                            onSubmit={(event) => {
                                form.handleSubmit(submitForm)(event).catch((formError) => {
                                    setErrorMessage(formError.message);
                                });
                            }}
                        >
                            <div className="tw-text-center tw-bg-gray-150 tw-p-4">
                                <h4 className="tw-mb-0">We can get this item for you.</h4>
                            </div>
                            <div className="tw-bg-gray-100 tw-p-6 tw-mb-4">
                                {showSuccess && (
                                    <Alerts
                                        message="Success! Your item inquiry has been sent."
                                        variant="success"
                                    />
                                )}
                                <Alerts
                                    message={errorMessage}
                                    variant="danger"
                                />
                                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                    <div className="tw-w-full tw-relative">
                                        <Input
                                            error={form?.formState?.errors?.mroName?.message as string}
                                            id="mroName"
                                            label="Name"
                                            maxLength={45}
                                            name="mroName"
                                            required={true}
                                            type="text"
                                            autocorrect="off"
                                            {...form.register(`mroName`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroEmail?.message as string}
                                            id="mroEmail"
                                            label="E-mail Address"
                                            maxLength={45}
                                            name="mroEmail"
                                            required
                                            type="text"
                                            autocorrect="off"
                                            {...form.register(`mroEmail`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroPhone?.message as string}
                                            formatPhoneNumber={true}
                                            label="Phone Number"
                                            name="mroPhone"
                                            numbersOnly={true}
                                            {...form.register(`mroPhone`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroQty?.message as string}
                                            id="mroQty"
                                            label="Desired Order Qty"
                                            name="mroQty"
                                            type="number"
                                            {...form.register(`mroQty`)}
                                        />
                                    </div>
                                    <div className="tw-w-full tw-relative">
                                        <TextArea
                                            className="tw-mb-1 tw-pb-6"
                                            displayCounter={true}
                                            error={form?.formState?.errors?.mroComment?.message as string}
                                            placeholder="Comment"
                                            maxLength={900}
                                            name="mroComment"
                                            style={{resize: `none`}}
                                            {...form.register(`mroComment`)}
                                        />
                                    </div>
                                </div>
                                <div className="tw-grid tw-grid-cols-1">
                                    <div className="tw-w-full tw-relative">
                                        <Button
                                            className="tw-w-full md:!tw-h-[52px] md:!tw-text-xl"
                                            block={true}
                                            type="submit"
                                            size="sm"
                                            variant="secondary"
                                        >
                                            Submit Inquiry
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                    <div className="tw-text-center">
                        <h5 className="tw-font-bold">Contact Dedicated Service Advisor</h5>
                        <div>
                            Call 1-800-558-2808<span className="tw-ml-3">|</span>
                            <Button
                                onClick={showChatWindow}
                                variant="link"
                            >
                                Start Chat
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
