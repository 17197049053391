import * as React from 'react';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form} from 'formik';
import {Modal} from 'react-bootstrap';
import {ReactNode} from 'react';

interface ContentModalProps {
    children: ReactNode;
    e2eLabel?: string;
    enforceFocus?: boolean;
    footer?: JSX.Element;
    form?: boolean;
    hideHeader?: boolean;
    noBottomPadding?: boolean;
    onClose: () => void;
    show: boolean;
    title?: string | JSX.Element;
}

export const ContentModal = ({
    children,
    e2eLabel,
    enforceFocus = true,
    footer,
    form,
    hideHeader,
    noBottomPadding,
    onClose,
    show,
    title,
}: ContentModalProps) => {
    /**
     * Contents template
     */
    const contents = (
        <>
            {!hideHeader && (
                <Modal.Header>
                    {title && <Modal.Title>{title}</Modal.Title>}
                    <button
                        aria-label="Close"
                        data-e2e="closeModal"
                        onClick={() => onClose()}
                        type="button"
                    >
                        <FontAwesomeIcon
                            color="#e5e5e5"
                            icon={faTimes}
                            size="3x"
                        />
                    </button>
                </Modal.Header>
            )}
            <Modal.Body className={`${noBottomPadding ? 'tw-px-8 tw-pt-8 !tw-pb-0' : 'tw-p-8'}`}>{children}</Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </>
    );

    /**
     * Template
     */
    return (
        <Modal
            animation={false}
            centered
            className="modal-fullscreen-sm p-0 react-modal"
            data-e2e={e2eLabel}
            enforceFocus={enforceFocus}
            onHide={onClose}
            scrollable
            show={show}
        >
            {form ? <Form style={{display: `contents`}}>{contents}</Form> : contents}
        </Modal>
    );
};
