import * as React from 'react';

import {ContentModal} from '../ui/modals/ContentModal';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '../ui/Buttons/Button';

interface ErrorModalProps {
    errorMsg: string;
    onClose: () => void;
    show: boolean;
}

export const ErrorModal = ({errorMsg, onClose, show}: ErrorModalProps) => (
    <ContentModal
        footer={
            <Button
                className="!tw-h-[38px] !tw-text-base md:!tw-h-[52px] md:!tw-text-xl"
                modal={true}
                onClick={onClose}
                type="button"
                variant={'outline-secondary'}
            >
                Close
            </Button>
        }
        onClose={onClose}
        show={show}
        title=""
    >
        <>
            <h4>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Sorry, {errorMsg}
            </h4>
            <div>
                Please contact your Dedicated Account Advisor at <strong>1-800-558-2808</strong>&nbsp; if you need assistance
            </div>
        </>
    </ContentModal>
);
