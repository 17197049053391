import * as React from 'react';
import {faSort, faSortAsc, faSortDesc} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface SortableHeaderProps {
    changeSort: (newSortField: string, newSortDec: boolean) => void;
    className?: string;
    currentField: string;
    fieldDesc: string;
    fieldName: string;
    showBorder?: boolean;
    sortDec: boolean;
}

export const SortableHeader = ({changeSort, className, currentField, fieldName, fieldDesc, showBorder, sortDec}: SortableHeaderProps) => {
    /**
     * Select sort icon
     */
    const pickIcon = () => {
        if (fieldName === currentField) {
            return sortDec ? faSortDesc : faSortAsc;
        } else {
            return faSort;
        }
    };

    /**
     * Template
     */
    return (
        <th
            className={`${className ? className + ' ' : ''}${showBorder ? 'tw-border-t' : ''} tw-cursor-pointer`}
            onClick={() => {
                if (fieldName === currentField) {
                    changeSort(fieldName, !sortDec);
                } else {
                    changeSort(fieldName, false);
                }
            }}
        >
            {fieldDesc} <FontAwesomeIcon icon={pickIcon()} />
        </th>
    );
};
