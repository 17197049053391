import * as React from 'react';
import {faChevronLeft, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode} from 'react';

import {FlyoutMenuStyles} from './FlyoutMenu.styles';

interface FlyoutModalProps {
    children: ReactNode;
    isSubMenu?: boolean;
    onClose: () => void;
    resetMenu?: () => void;
    show: boolean;
    title: string;
    width?: number;
}

const FlyoutMenu = ({children, isSubMenu = false, onClose, resetMenu, show, title, width = 540}: FlyoutModalProps) => {
    const contents = (
        <>
            <div className="d-flex justify-content-between border-bottom align-items-center px-3 pt-2 pb-1">
                <div className="d-flex align-items-center">
                    {isSubMenu && (
                        <FontAwesomeIcon
                            className="gray-700 pointer mr-3 py-2"
                            icon={faChevronLeft}
                            onClick={() => {
                                resetMenu();
                            }}
                            size="xl"
                        />
                    )}
                    <div className="m-0 h4">{title}</div>
                </div>
                <FontAwesomeIcon
                    className="gray-700 pointer p-2"
                    icon={faTimes}
                    onClick={() => {
                        onClose();
                    }}
                    size="2xl"
                />
            </div>
            <div className="tw-p-0 tw-overflow-x-hidden tw-h-[calc(100vh-62px)] tw-text-base">{children}</div>
        </>
    );

    /**
     * Template
     */
    return (
        <FlyoutMenuStyles>
            <div
                className={`flyoutContainer ${show ? 'show' : 'hide'}`}
                onClick={() => {
                    onClose();
                }}
            ></div>
            <div
                className={`tw-overflow-x-hidden flyoutMenu ${show ? 'show' : 'hide'}`}
                style={{maxWidth: width, transform: show ? `translate3d(0vw, 0, 0)` : `translate3d(${width}px, 0, 0)`}}
            >
                {contents}
            </div>
        </FlyoutMenuStyles>
    );
};

export {FlyoutMenu};
