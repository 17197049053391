import * as React from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {faMinus, faPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Ref, useEffect, useState} from 'react';

import {StepperContainer} from './Stepper.styles';

export const stepperErrorInitialState: StepperError = {error: ``, message: ``};

export interface StepperError {
    error: string;
    message: string;
}

export type StepperProps = {
    className?: string;
    disabled?: boolean;
    error?: string;
    large?: boolean;
    minimum?: number;
    maximum?: number;
    name?: string;
    onError?: (stepperError: StepperError) => void;
    onUpdate?: (val) => void;
    onUpdateAllowInvalid?: boolean;
    value?: number;
    ref?: Ref<unknown> | null;
} & JSX.IntrinsicElements[`input`];

export const StepperComponent = (
    {
        className,
        disabled,
        error,
        maximum = 9999,
        large = false,
        minimum = 1,
        name,
        onError,
        onUpdate,
        onUpdateAllowInvalid,
        value = 1,
    }: StepperProps,
    ref,
) => {
    const [stepperErrorState, setStepperErrorState] = useState<StepperError>(stepperErrorInitialState);
    const [stepperValue, setStepperValue] = useState(value);

    // If the value is changed externally, update internal value
    useEffect(() => {
        setStepperValue(value);
    }, [value]);

    // minimum / maximum Validation Handling
    useEffect(() => {
        if (!isNaN(stepperValue) && (stepperValue !== value || stepperErrorState !== stepperErrorInitialState)) {
            if (stepperValue > maximum) {
                const stepperError = {error: `Limit ${maximum}`, message: `Order quantity limit exceeded`};
                setStepperErrorState(stepperError);
                if (onUpdateAllowInvalid && onUpdate) {
                    onUpdate(stepperValue);
                }
                if (onError) {
                    onError(stepperError);
                }
            } else if (stepperValue < minimum) {
                const stepperError = {error: `${minimum} required`, message: `Set order quantity to ${minimum} or greater`};
                setStepperErrorState(stepperError);
                if (onUpdateAllowInvalid && onUpdate) {
                    onUpdate(stepperValue);
                }
                if (onError) {
                    onError(stepperError);
                }
            } else {
                setStepperErrorState(stepperErrorInitialState);
                if (onError) {
                    onError(stepperErrorInitialState);
                }
                if (stepperValue !== value && onUpdate) {
                    onUpdate(stepperValue);
                }
            }
        }
        // We only want stepperValue to trigger changes in this hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepperValue]);

    /**
     * Template
     */
    return (
        <StepperContainer>
            <div className={`${large ? 'stepper-component stepper-lg stepper-full' : 'stepper-component'} ${className}`}>
                <InputGroup className="incrementer">
                    <InputGroup.Prepend>
                        <Button
                            disabled={disabled || stepperValue <= minimum}
                            onClick={() => {
                                setStepperValue(stepperValue - 1);
                            }}
                            variant={disabled || stepperValue <= minimum ? `outline-light` : `outline-dark`}
                        >
                            <FontAwesomeIcon
                                className={`${disabled ? 'tw-text-gray-400' : 'tw-text-gray-650'} ${large ? 'tw-align-middle' : ''} `}
                                icon={faMinus}
                            />
                        </Button>
                    </InputGroup.Prepend>
                    <input
                        className={`${large ? 'tw-text-xl' : ''} form-control text-center${error ? ' error' : ''}`}
                        disabled={disabled || (stepperValue === maximum && stepperValue === minimum)}
                        inputMode="numeric"
                        maxLength={3}
                        name={name}
                        onChange={(e) => {
                            setStepperValue(parseInt(e.target.value));
                        }}
                        pattern="[0-9]+"
                        size={5}
                        type="number"
                        value={stepperValue}
                        ref={ref}
                    />
                    <InputGroup.Append>
                        <Button
                            className={`${large ? 'hover:!tw-bg-transparent focus:tw-bg-transparent' : ''}`}
                            disabled={disabled || stepperValue >= maximum}
                            onClick={() => {
                                setStepperValue(stepperValue + 1);
                            }}
                            variant={disabled || stepperValue >= maximum ? `outline-light` : `outline-dark`}
                        >
                            <FontAwesomeIcon
                                className={`${disabled ? 'tw-text-gray-400' : 'tw-text-gray-650'} ${
                                    large ? 'tw-align-middle tw-text-2xl' : ''
                                } `}
                                icon={faPlus}
                            />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                <div className="d-flex justify-content-between">{error && <Form.Label className="error caption">{error}</Form.Label>}</div>
            </div>
        </StepperContainer>
    );
};
export const Stepper = React.forwardRef(StepperComponent);
